const acceptanceLevels = {
  en: {
    tooltip: {
      H: "Many locations accept your card here",
      G: "Growing number of locations accept your card here",
      J: "Limited locations accept your card here",
      "NO COVERAGE": "No locations accept your card here",
    },
    legend: {
      headline: "Global Acceptance",
    },
    modal: {
      headline: "Global Acceptance",
    },
    levels: {
      H: {
        title: "Many Locations",
        desc: "Many locations accept your card, especially those in popular travel destinations.",
      },
      G: {
        title: "Expanding Locations",
        desc: "A growing number of locations accept your card, especially restaurants, hotels, and retailers.",
      },
      J: {
        title: "Limited Locations",
        desc: "A limited number of locations accept your card.",
      },
      "NO COVERAGE": {
        title: "No Locations Yet",
        desc: "Your card is not yet accepted here.",
      },
    },
    headlines: {
      H: "Many locations accept your card, especially those in popular travel destinations.",
      G: "A growing number of locations accept your card, especially restaurants, hotels, and retailers.",
      J: "A limited number of locations accept your card.",
      "NO COVERAGE":
        "Your card is not yet accepted here—but we are working on it. Our network is expanding all the time.",
    },
  },
  es: {
    tooltip: {
      H: "Muchas ubicaciones aceptan su tarjeta aquí",
      G: "Un número creciente de ubicaciones aceptan su tarjeta aquí",
      J: "Un número limitado de ubicaciones aceptan su tarjeta aquí",
      "NO COVERAGE": "Ninguna ubicación acepta su tarjeta aquí",
    },
    legend: {
      headline: "Aceptación global",
    },
    modal: {
      headline: "Aceptación global",
    },
    levels: {
      H: {
        title: "Muchas ubicaciones",
        desc: "Muchas ubicaciones aceptan su tarjeta, especialmente en destinos de viaje populares",
      },
      G: {
        title: "Ubicaciones en Expansión",
        desc: "Un número creciente de ubicaciones aceptan su tarjeta, especialmente restaurantes, hoteles y comercios.",
      },
      J: {
        title: "Ubicaciones Limitadas",
        desc: "Un número limitado de ubicaciones aceptan su tarjeta.",
      },
      "NO COVERAGE": {
        title: "Aún no hay ubicaciones",
        desc: "Su tarjeta aún no es aceptada aquí.",
      },
    },
    headlines: {
      H: "Muchas ubicaciones aceptan su tarjeta, especialmente aquellas en destinos de viaje populares.",
      G: "Un número creciente de ubicaciones aceptan su tarjeta, especialmente restaurantes, hoteles y comercios.",
      J: "Un número limitado de ubicaciones aceptan su tarjeta.",
      "NO COVERAGE":
        "Su tarjeta aún no se acepta aquí, pero estamos trabajando en ello. Nuestra red se está expandiendo todo el tiempo.",
    },
  },
};

export default acceptanceLevels;
