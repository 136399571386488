import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { MapContext } from "../../contexts";
import { useGoogleMaps } from "../../hooks/useGoogleMaps";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material/";
import { COUNTRY_MAP_STYLES, CITY_MAP_STYLES } from "./mapstyles";
import {
  makeCountryMap,
  makeCityMap,
  assignAcceptanceMapData,
  countryView_addListeners,
  countryView_pauseEvents,
  getInitialZoom,
  createCustomZoomControl,
} from "./mapmethods";
import { Helmet } from "react-helmet";

import {
  makeCityPins,
  makeCityClusters,
  makeMerchantPins,
  makeMerchantClusters,
} from "./pins_clusters.js";
import BottomDrawer from "../BottomDrawer";
import Header from "../Header";
import MapLegend from "../MapLegend";
// https://github.com/nvkelso/natural-earth-vector/tree/master/geojson with minor modifications
import countryData from "../../data/countries_new.json";
import MapFilters from "../CityView/MapFilters";
import CityHeader from "../CityView/CityHeader";
import isTouchDevice from "../../util/isTouchDevice";
import useMerchantData from "../../api/useMerchantData";
import MerchantTooltip from "../CityView/MerchantTooltip";
import SearchDrawer from "../SearchDrawer";
//import ShowMe from "../CityView/ShowMe";
import { useHistory, useLocation } from "react-router-dom";
import isDefaultBrand from "../../util/isDefaultBrand";
import ErrorBoundary from "../ErrorBoundary";
import trackLocationChange from "../../util/trackLocationChange";

const PREFIX = "Map";

const classes = {
  gm: `${PREFIX}-gm`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.gm}`]: {
    opacity: 0,
    transition: "opacity 1s",
    "& button.gm-ui-hover-effect": {
      display: "none !important",
    },
    "& .gm-style iframe + div": { border: "none! important" },
    "& .zoom-control": {
      borderRadius: 8,
      overflow: "hidden",
      marginBottom: 30,
      marginRight: 30,
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
      "& button": {
        height: 40,
        width: 40,
        border: "none",
        background: theme.palette.common.white,
        display: "block",
        color: theme.palette.secondary.main,
        fontSize: 36,
        lineHeight: 0,
        fontFamily:
          "Arial, sans-serif" /*using basic font here intentionally for plus and minus signs*/,
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
        "&:first-child": {
          borderBottom: `1px solid ${theme.palette.borders.light}`,
        },
        "&:hover, &:focus": {
          cursor: "pointer",
          color: `${theme.palette.primary.main}`,
          outline: "none",
        },
      },
    },
  },
}));

const Map = ({ acceptanceData, cities, defaultCityView, ...props }) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const {
    mapCenter,
    google,
    merchants,
    cityView,
    setMerchants,
    setCityView,
    setCities,
    setSelectedCountry,
    setAcceptanceData,
    mapLoading,
    setMapLoading,
    listView,
    setFilter,
    setSearchDrawer,
    activeTooltip,
    locationShared,
    setLocationShared,
    setDrawer,
  } = useContext(MapContext);

  //get all City Merchants and store
  const { data: merchantsData, error: merchantsError } = useMerchantData(
    "merchants",
    {
      merchant_city: cityView,
      pagesize: 100000,
    }
  );

  const zoom = getInitialZoom();

  const [fadeMap, setFadeMap] = useState(false);
  const [cityPins, setCityPins] = useState(null);
  const [filteredMerchants, setFilteredMerchants] = useState(merchants);
  const [visibleMerchants, setVisibleMerchants] = useState(null);
  const [merchantTooltip, setMerchantTooltip] = useState(null);
  const [activePin, setActivePin] = useState(null);

  //set these in context for convenience
  useEffect(() => {
    if (defaultCityView) {
      setMapLoading(true);
      setCityView(defaultCityView);
    }
    setCities(cities);
    setAcceptanceData(acceptanceData);
    // an empty dependencies array is ok
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { centerBoundsAndZoom, ref, map } = useGoogleMaps(google, {
    center: mapCenter,
    zoom: zoom,
    minZoom: zoom,
    clickableIcons: false,
    disableDefaultUI: true,
    zoomControl: false,
    fullscreenControl: false,
    gestureHandling: "greedy", // or cooperative?
    restriction: {
      latLngBounds: {
        north: 85,
        south: -65,
        west: -180,
        east: 180,
      },
      strictBounds: true,
    },
  });

  const clearLocation = useCallback(() => {
    if (locationShared.length > 0) locationShared[0].setMap(null);
    setLocationShared([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }
    if (location.pathname === "/guides") {
      setMapLoading(true);
      setSearchDrawer(false);
      setCityView(false);
      setMerchants(null);
      setFilter(null);
      clearLocation();
      setSelectedCountry(null);
      map.setZoom(zoom);
      if (activeTooltip?.current) activeTooltip.current.close();
      setTimeout(() => setMapLoading(false), 900);
    }
  }, [
    activeTooltip,
    clearLocation,
    location,
    setCityView,
    setFilter,
    zoom,
    setSearchDrawer,
    setMerchants,
    setMapLoading,
    setSelectedCountry,
    map,
  ]);
  const countryMapType = useRef();
  const cityMapType = useRef();
  //saving these in a state variable causes a meltdown
  const clusters = useRef();

  useEffect(() => {
    if (merchantsError) {
      history.push("/techdiff");
      trackLocationChange();
    }
    if (merchantsData) {
      setMerchants(merchantsData.result);
    }
  }, [history, merchantsData, merchantsError, setMerchants]);

  useEffect(() => {
    if (google && map) {
      countryMapType.current = new google.maps.StyledMapType(
        COUNTRY_MAP_STYLES,
        {
          name: "Acceptance Map",
        }
      );
      cityMapType.current = new google.maps.StyledMapType(CITY_MAP_STYLES, {
        name: "City Guide Map",
      });
      map.data.addGeoJson(countryData);
      assignAcceptanceMapData(map, acceptanceData);
      if (!cityPins) {
        const cPins = cities.map((p) =>
          makeCityPins(p, map, theme, isDefaultBrand(theme))
        );
        setCityPins(cPins);
        makeCityClusters(cPins, map);
        countryView_addListeners(
          map,
          cPins,
          theme,
          setSelectedCountry,
          setCityView,
          setMapLoading,
          setMerchants,
          setFilteredMerchants,
          history,
          activeTooltip
        );
      }
    }
  }, [
    acceptanceData,
    cities,
    cityPins,
    cityView,
    history,
    theme,
    google,
    map,
    setCityView,
    setMapLoading,
    setSelectedCountry,
    setMerchants,
    setFilteredMerchants,
    activeTooltip,
  ]);

  useEffect(() => {
    if (!fadeMap && map && google) {
      createCustomZoomControl(map, google);
      setTimeout(() => setFadeMap(true), 300);
    }
  }, [map, google, fadeMap]);

  useEffect(() => {
    if (!map || !cityPins) return;
    if (cityView) {
      makeCityMap(map, cityMapType.current);
      countryView_pauseEvents(true);
      cityPins?.forEach((c) => {
        c.merchantData.city === cityView
          ? c.setVisible(false)
          : c.setVisible(true);
      });
    } else {
      makeCountryMap(map, countryMapType.current, theme);
      countryView_pauseEvents(false);
      cityPins?.forEach((c) => {
        c.setVisible(true);
      });
      setTimeout(() => setMapLoading(false), 100);
    }
  }, [
    cityView,
    cityPins,
    map,
    cityMapType,
    countryMapType,
    setMapLoading,
    theme,
  ]);

  useEffect(() => {
    if (!google) {
      return;
    }
    //wipe out the clusterer when the merchants array changes
    if (clusters.current) {
      window.google.maps.event.clearListeners(
        clusters.current,
        "clusteringend"
      );
      clusters.current.clearMarkers();
      clusters.current = null;
    }

    if (map && merchants) {
      const currentMerchants = filteredMerchants ?? merchants;
      const mPins = currentMerchants.map((p) =>
        makeMerchantPins(p, map, !isDefaultBrand(theme), theme)
      );
      clusters.current = makeMerchantClusters(mPins, map, google.maps);
      cityView === "Los Angeles"
        ? centerBoundsAndZoom(mPins, 10)
        : centerBoundsAndZoom(mPins);

      if (mPins)
        mPins.forEach((c) => {
          google.maps.event.addListener(c, "click", function (e) {
            if (window.scrollY !== 0) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }

            mPins.forEach((m) => {
              m.set("active", false);
              m.setIcon(
                `${!isDefaultBrand(theme) ? `/brand/${theme.brand}/` : "/"}${
                  m.merchantData.mcc
                }_default.svg`
              );
              setActivePin(null);
            });
            c.setIcon(
              `${!isDefaultBrand(theme) ? `/brand/${theme.brand}/` : "/"}${
                c.merchantData.mcc
              }_active.svg`
            );
            c.set("zIndex", 1000);
            c.set("active", true);
            setActivePin(c);
            if (mdUp) {
              map.panTo({
                lat: c.position.lat(),
                lng: c.position.lng(),
              });
              setDrawer(c.merchantData);
            } else {
              setMerchantTooltip(c.merchantData);
            }
          });
          if (!isTouchDevice()) {
            google.maps.event.addListener(c, "mouseover", function () {
              mPins.forEach((m) => {
                if (!m.active) {
                  c.setIcon(
                    `${
                      !isDefaultBrand(theme) ? `/brand/${theme.brand}/` : "/"
                    }${m.merchantData.mcc}_default.svg`
                  );
                  c.set("zIndex", "");
                }
              });
              c.set("labelClass", "hovered");
              c.setIcon(
                `${!isDefaultBrand(theme) ? `/brand/${theme.brand}/` : "/"}${
                  c.merchantData.mcc
                }_active.svg`
              );
              c.set("zIndex", 1000);
            });
            google.maps.event.addListener(c, "mouseout", function () {
              if (map.zoom <= 16) {
                c.set("labelClass", "");
              }
              if (!c.active) {
                c.setIcon(
                  `${!isDefaultBrand(theme) ? `/brand/${theme.brand}/` : "/"}${
                    c.merchantData.mcc
                  }_default.svg`
                );
                c.set("zIndex", "");
              }
            });
          }
          google.maps.event.addListener(map, "zoom_changed", function () {
            if (map.zoom > 16) {
              c.set("labelClass", "hovered");
            } else {
              c.set("labelClass", "");
            }
          });
        });

      window.google.maps.event.addListener(map, "idle", function () {
        setTimeout(function () {
          if (!cityView || !clusters.current || !map.getBounds()) {
            return false;
          }
          const visible = clusters.current
            .getClusters()
            .filter((m) =>
              map.getBounds().contains(m.markers_[0].getPosition())
            );

          setVisibleMerchants(visible);
        }, 10);
      });

      setTimeout(() => setMapLoading(false), 100);
    }
    // centerBoundsAndZoom flagging errors but it's ok asis
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cityPins,
    filteredMerchants,
    google,
    map,
    merchants,
    cityView,
    setMapLoading,
  ]);

  return (
    <Root>
      <Helmet>
        <title>
          {cityView
            ? `${cityView} Travel Guide - Discover Global Network Travel Guides`
            : "International Credit Card Acceptance - Discover Global Network Travel Guides"}
        </title>
        <meta
          name="description"
          content={
            cityView
              ? `Looking for places to use your card in ${cityView}? Browse cardholder's favorite places to shop, stay, eat and more, all over town.`
              : "Explore the global map and see where to use your card, all around the world."
          }
        />
        <meta
          name="keywords"
          content={
            cityView
              ? `Discover card ${cityView} travel guide, Discover card ${cityView} shopping guide, Discover card ${cityView} dining guide, Discover card ${cityView} hotel guide, Discover card ${cityView} attractions guide`
              : "Discover card international acceptance, Discover card global acceptance, Discover card worldwide acceptance, Discover card acceptance in Europe, Discover card acceptance in the Middle East, Discover card acceptance in Africa,  Discover card acceptance in Asia, Discover card acceptance in the Asia Pacific, Discover card acceptance in North America,  Discover card acceptance in South America, Discover card acceptance in Central America, Where is Discover Network card accepted internationally, Where to use Discover credit card worldwide, Where is my Discover Network card accepted, Where is Discover Network card accepted worldwide"
          }
        />
        <link
          rel="canonical"
          href={`https://travel.discoverglobalnetwork.com/guides/${
            cityView ? cities.find((c) => c.city === cityView)?.route : ""
          }`}
        />
      </Helmet>
      <Header cities={cities} />
      <ErrorBoundary>
        {cityView && merchants && (
          <>
            <Box width="100%" top="62px" position="fixed" zIndex={2}>
              <CityHeader
                cityView={cityView}
                filteredMerchants={filteredMerchants}
                setFilteredMerchants={setFilteredMerchants}
              />
            </Box>
            <MapFilters
              filteredMerchants={filteredMerchants}
              setFilteredMerchants={setFilteredMerchants}
              setMerchantTooltip={setMerchantTooltip}
              map={map}
              zoom={zoom}
            />
          </>
        )}
        <Box
          position="fixed"
          height={
            cityView
              ? mdUp
                ? "calc(60vh - 76px)"
                : "calc(60vh - 52px)"
              : "60vh"
          }
          width="100%"
          top={cityView ? (mdUp ? 281 : 174) : 62}
        >
          <div
            className={classes.gm}
            ref={ref}
            style={{
              width: "100%",
              height: "100%",
              opacity: fadeMap ? "1" : "0",
              display: listView ? "none" : "block",
            }}
          />
          {/*cityView && <ShowMe map={map} />*/}
          {merchantTooltip && (
            <MerchantTooltip
              merchantData={merchantTooltip}
              setMerchantTooltip={setMerchantTooltip}
              map={map}
              activePin={activePin}
            />
          )}
          {fadeMap && !cityView && <MapLegend />}
          {(!acceptanceData || !cities || mapLoading) && (
            <Box
              height="60vh"
              width="100%"
              position="fixed"
              top={cityView ? (mdUp ? 205 : 142) : 62}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
                bgcolor="common.white"
              >
                <CircularProgress color="secondary" />
              </Box>
            </Box>
          )}
        </Box>
        <BottomDrawer
          visibleMerchants={visibleMerchants}
          filteredMerchants={filteredMerchants}
          cityView={cityView}
          map={map}
          mapLoading={mapLoading}
          activePin={activePin}
          setActivePin={setActivePin}
          setFilteredMerchants={setFilteredMerchants}
        />
        <SearchDrawer
          map={map}
          zoom={zoom}
          setFilteredMerchants={setFilteredMerchants}
        />
      </ErrorBoundary>
    </Root>
  );
};

Map.propTypes = {
  acceptanceData: PropTypes.array,
  cities: PropTypes.array,
  defaultCityView: PropTypes.string,
  props: PropTypes.object,
};

export default Map;
