const countryDrawer = {
  en: {
    priorityCountries: {
      USA: "Your Discover® Card is accepted by 99% of the places across the country that take credit cards*.",
      JPN: "Many locations take your card, including many popular destinations in Tokyo, Osaka, and Kyoto.",
      PER: "Many locations take your card, including many popular destinations in Lima.",
      ESP: "Many locations take your card, including many top merchants in Barcelona, Madrid, and Seville.",
      URY: "Many locations take your card, including many destinations in Montevideo, Punta del Este, and Colonia del Sacramento.",
      ARG: "A growing number of locations take your card, including many popular destinations in Buenos Aires and Mendoza.",
      CHL: "A growing number of locations take your card, including many popular destinations in Santiago.",
      DEU: "Many locations accept your card, especially those in popular travel destinations.",
      MEX: "A growing number of locations take your card, including many top merchants in Cancun.",
      GBR: "Many locations accept your card, especially those in popular travel destinations.",
      CAN: "A growing number of locations take your card, including many of the most popular merchants in Vancouver and Toronto.",
      ITA: "A growing number of locations take your card, including many popular merchants in Rome.",
      PRT: "A growing number of locations take your card, including many popular merchants in Lisbon.",
      AUS: "A growing number of locations take your card, including top destinations in Sydney, Melbourne, and Brisbane.",
      FRA: "A growing number of locations take your card, including many of the most popular merchants in Paris.",
    },
    acceptanceMarkHeadline: {
      coverage: "Take your card wherever you go",
      noCoverage: "Traveling in the region? Take your card",
    },
    aboveMarksCopy: {
      coverage: "What to look for when you pay in ",
      noCoverage: {
        Europe: "What to look for when you pay in Europe",
        Africa: "In-network Payments in the Middle East & Africa",
        Asia: "In-network Payments in Asia Pacific",
        America: "What to look for when you pay in the Americas",
      },
    },
    acceptanceATMcoverage:
      "Your card is part of a global network, so you can use it anywhere you see these logos. Don't see any? Just ask.",
    noAcceptanceATMcoverage: {
      Europe:
        "You card is part of a growing global network. You can use your card across some of Europe's most popular destinations, including Spain, Germany, the UK, Italy, Portugal and France.",
      Africa:
        "You card is part of a growing global network. Use your card in the UAE, Egypt, Morocco and South Africa. With destinations in the Middle East & Africa expanding rapidly, you can use your card in more and more places here.",
      Asia: "Your card is part of a growing global network. Use your card almost everywhere in Japan as well as top travel destinations across Asia and Pacific Islands, including India, China, Singapore, Australia and more.",
      America:
        "Your card is part of a growing global network. You can use your card across Canada, Mexico and the U.S., as well as in popular South American travel spots like Peru, Uruguay, Argentina, Chile and more.",
      Antarctica:
        "Your card is part of a growing global network. You can use your card across Canada, Mexico and the U.S., as well as in popular South American travel spots like Peru, Uruguay, Argentina, Chile and more.",
    },
    regions: {
      Europe: "Europe",
      Africa: "the Middle East & Africa",
      Asia: "Asia Pacific",
      America: "the Americas",
    },
    atm: {
      coverage: {
        headline: "Need cash while you’re here?",
        subtitle: "Use your card to get local currency at these ATMs:",
        supplmentary:
          "All banks displaying Discover Network, Diners Club International and the PULSE Logos",
        fees: "Fees may apply.",
      },
      coverageAlt: {
        headline: "You can use your card at these ATMs",
        subtitle: "Use your card to get local currency at these ATMs:",
        supplmentary:
          "All banks displaying Discover Network, Diners Club International and the PULSE Logos",
        fees: "Fees may apply.",
      },
      noCoverage: {
        headline: "ATM coverage is emerging",
        subtitle: "We are working to establish ATM acceptance in this country.",
      },
    },
  },
  es: {
    priorityCountries: {
      USA: "Su tarjeta Discover® es aceptada por el 99% de los lugares en todo el país que aceptan tarjetas de crédito.*",
      JPN: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Tokio, Osaka y Kioto.",
      PER: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Lima.",
      ESP: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos comercios destacados en Barcelona, Madrid y Sevilla.",
      URY: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos destinos en Montevideo, Punta del Este y Colonia del Sacramento.",
      ARG: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Buenos Aires y Mendoza.",
      CHL: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Santiago.",
      DEU: "Muchos ubicaciones aceptan su tarjeta, especialmente aquellos en destinos turísticos populares.",
      MEX: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos comercios destacados en Cancún.",
      GBR: "Muchos ubicaciones aceptan su tarjeta, especialmente aquellos en destinos turísticos populares.",
      CAN: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos de los comercios más populares en Vancouver y Toronto.",
      ITA: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos comercios populares en Roma.",
      PRT: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos comerciantes populares en Lisboa.",
      AUS: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo destinos principales en Sídney, Melbourne y Brisbane.",
      FRA: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos de los comercios más populares en París.",
    },
    acceptanceMarkHeadline: {
      coverage: "Llevá tu tarjeta dondequiera que vayas",
      noCoverage: "¿Viaja en la región? Lleve su tarjeta",
    },
    aboveMarksCopy: {
      coverage: "¿Qué buscar cuando paga en ",
      noCoverage: {
        Europe: "¿Qué buscar cuando paga en Europa?",
        Africa: "Pagos dentro de la red en Medio Oriente y África",
        Asia: "Pagos dentro de la red en Asia Pacífico",
        America: "¿Qué buscar cuando paga en América?",
      },
    },
    acceptanceATMcoverage:
      "Su tarjeta forma parte de una red global, por lo que puede usarla en cualquier lugar donde vea estos logos. ¿No ve ninguno? Simplemente pregunte.",
    noAcceptanceATMcoverage: {
      Europe:
        "Su tarjeta forma parte de una red global en crecimiento. Puede utilizar su tarjeta en algunos de los destinos más populares de Europa, incluidos España, Alemania, el Reino Unido, Italia, Portugal y Francia.",
      Africa:
        "Su tarjeta forma parte de una red global en crecimiento. Use su tarjeta en los EAU, Egipto, Marruecos y Sudáfrica. Con destinos en Oriente Medio y África que se expanden rápidamente, puede usar su tarjeta en cada vez más lugares aquí.",
      Asia: "Su tarjeta forma parte de una red global en crecimiento. Use su tarjeta en casi todas partes de Japón, así como en los principales destinos de viaje en Asia e Islas del Pacífico, incluidas India, China, Singapur, Australia y más.",
      America:
        "Su tarjeta forma parte de una red global en crecimiento. Puede usar su tarjeta en Canadá, México y los EE. UU., así como en lugares de viaje populares de América del Sur como Perú, Uruguay, Argentina, Chile y más.",
      Antarctica:
        "Su tarjeta forma parte de una red global en crecimiento. Puede usar su tarjeta en Canadá, México y los EE. UU., así como en lugares de viaje populares de América del Sur como Perú, Uruguay, Argentina, Chile y más.",
    },
    regions: {
      Europe: "Europa",
      Africa: "Medio Oriente y África",
      Asia: "Asia Pacífico",
      America: "América",
    },
    atm: {
      coverage: {
        headline: "¿Necesita dinero en efectivo mientras está aquí?",
        subtitle:
          "Use su tarjeta para obtener moneda local en estos cajeros automáticos:",
        supplmentary:
          "Todos los bancos que muestren los logos de Discover Network, Diners Club International y PULSE",
        fees: "Pueden aplicarse cargos.",
      },
      coverageAlt: {
        headline: "Puede usar su tarjeta en estos cajeros automáticos",
        subtitle:
          "Use su tarjeta para obtener moneda local en estos cajeros automáticos:",
        supplmentary:
          "Todos los bancos que muestren los logos de Discover Network, Diners Club International y PULSE",
        fees: "Pueden aplicarse cargos.",
      },
      noCoverage: {
        headline: "La cobertura de cajeros automáticos está creciendo",
        subtitle:
          "Estamos trabajando para establecer la aceptación en cajeros automáticos en este país.",
      },
    },
  },
};

export default countryDrawer;
