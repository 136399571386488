const cityViewCopy = {
  en: {
    whileTraveling: "While you're traveling in",
    the: "the",
    more: "More",
    travelGuidesToExplore: "travel guides to explore",
    looksLikeYoureIn: "Looks like you're in XX right now",
    wantToSee: "Want to see our Travel Guide for XX?",
    wishYouWereHere: "Wish you were here",
    nextTime:
      "Looks like you're not in XX right now. Next time you're visiting, be sure to check out the Travel Guide and see what's nearby.",
    acceptedPaymentsIn: "Accepted Payments in",
    nilson: "According to the Feb 2024 issue of the Nilson Report.",
    honolulu: {
      shortGreeting: "Aloha!",
      marqueeHeadline: "Aloha! Let's explore Honolulu.",
      mobileHeadline: "Aloha Honolulu!",
      travelGuideHeadline:
        "The top Honolulu hot spots our cardholders visit most",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    losangeles: {
      shortGreeting: "Hey!",
      marqueeHeadline: "Hey! Let's explore Los Angeles.",
      mobileHeadline: "Hey Los Angeles!",
      travelGuideHeadline:
        "The can't-miss L.A. destinations according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    newyork: {
      shortGreeting: "Hi!",
      marqueeHeadline: "Hi! Let's explore New York City.",
      mobileHeadline: "Hi New York City!",
      travelGuideHeadline:
        "The ultimate list of NYC hot spots, according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos:",
    },
    miami: {
      shortGreeting: "Hola!",
      marqueeHeadline: "Hola! Let's explore Miami.",
      mobileHeadline: "Hola Miami!",
      travelGuideHeadline: "Miami's must-see spots, according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    sanjuan: {
      shortGreeting: "Hola!",
      marqueeHeadline: "Hola! Let's explore San Juan.",
      mobileHeadline: "Hola San Juan!",
      travelGuideHeadline:
        "The hottest spots in San Juan, according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    dubai: {
      shortGreeting: "Salam!",
      marqueeHeadline: "Salam! Let's explore Dubai.",
      mobileHeadline: "Salam Dubai!",
      travelGuideHeadline:
        "See the top destinations across Dubai cardholders visit most",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    tokyo: {
      shortGreeting: "こんにちは",
      marqueeHeadline: "Konnichiwa! Let's explore Tokyo.",
      mobileHeadline: "Konnichiwa Tokyo!",
      travelGuideHeadline: "Tokyo's top destinations, according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    seoul: {
      shortGreeting: "여보!",
      marqueeHeadline: "An nyeong ha seyo! Let's explore Seoul.",
      mobileHeadline: "An nyeong ha seyo Seoul!",
      travelGuideHeadline:
        "Cardholders say these are the can't-miss stops in Seoul",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    vancouver: {
      shortGreeting: "Hey there!",
      marqueeHeadline: "Hey there! Let's explore Vancouver.",
      mobileHeadline: "Hey there Vancouver!",
      travelGuideHeadline: "Our cardholder's favorite spots across Vancouver",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    toronto: {
      shortGreeting: "Hey there!",
      marqueeHeadline: "Hey there! Let's explore Toronto.",
      mobileHeadline: "Hey there Toronto!",
      travelGuideHeadline:
        "The top Toronto travel spots our cardholders keep visiting",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    bangkok: {
      shortGreeting: "สวัสดี!",
      marqueeHeadline: "Sawasdee! Let's explore Bangkok.",
      mobileHeadline: "Sawasdee Bangkok!",
      travelGuideHeadline:
        "Bangkok's best destinations according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    london: {
      shortGreeting: "Hello!",
      marqueeHeadline: "Hello! Let's explore London.",
      mobileHeadline: "Hello London!",
      travelGuideHeadline:
        "Our cardholder's ultimate list of London destinations",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    berlin: {
      shortGreeting: "Hallo!",
      marqueeHeadline: "Hallo! Let's explore Berlin.",
      mobileHeadline: "Hallo Berlin!",
      travelGuideHeadline: "The best of Berlin according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    istanbul: {
      shortGreeting: "Merhaba!",
      marqueeHeadline: "Merhaba! Let's explore Istanbul.",
      mobileHeadline: "Merhaba Istanbul!",
      travelGuideHeadline:
        "The top Istanbul destinations our cardholders visit most",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    lisbon: {
      shortGreeting: "Olá!",
      marqueeHeadline: "Olá! Let's explore Lisbon.",
      mobileHeadline: "Olá Lisbon!",
      travelGuideHeadline: "The top travel spots cardholders love in Lisbon",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    cancun: {
      shortGreeting: "Hola",
      marqueeHeadline: "Hola! Let's explore Cancun.",
      mobileHeadline: "Hola Cancun!",
      travelGuideHeadline: "Cancun's can't-miss spots according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    rome: {
      shortGreeting: "Salvē!",
      marqueeHeadline: "Salvē! Let's explore Rome.",
      mobileHeadline: "Salvē Rome!",
      travelGuideHeadline: "Cardholder's top travel spots across Rome",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    barcelona: {
      shortGreeting: "Hola!",
      marqueeHeadline: "Hola! Let's explore Barcelona.",
      mobileHeadline: "Hola Barcelona!",
      travelGuideHeadline: "The best of Barcelona according to cardholders",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
      calloutTitle: "Explore More of Barcelona",
      calloutCopy:
        "Find itineraries and travel tips for your Barcelona adventure.",
      calloutCTA: "Let's Go",
    },
    paris: {
      shortGreeting: "Salut!",
      marqueeHeadline: "Salut! Let's explore Paris.",
      mobileHeadline: "Salut Paris!",
      travelGuideHeadline: "Top Parisian hot spots our cardholders love",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    singapore: {
      shortGreeting: "你好",
      marqueeHeadline: "Hello! Let's explore Singapore.",
      mobileHeadline: "Hello Singapore!",
      travelGuideHeadline: "Singapore's best spots our cardholders visit most",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    seville: {
      shortGreeting: "Hola",
      marqueeHeadline: "Hola! Let's explore Seville.",
      mobileHeadline: "Hola Seville!",
      travelGuideHeadline:
        "The Seville destinations our cardholders visit again and again",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    madrid: {
      shortGreeting: "Hola",
      marqueeHeadline: "Hola! Let's explore Madrid.",
      mobileHeadline: "Hola Madrid!",
      travelGuideHeadline: "Our cardholder's most-visited places in Madrid",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    doha: {
      shortGreeting: "Salam alaykum!",
      marqueeHeadline: "Salam alaykum! Let's explore Doha.",
      mobileHeadline: "Salam alaykum Doha!",
      travelGuideHeadline:
        "The Doha destinations our cardholders keep visiting",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
  },
  es: {
    whileTraveling: "Mientras viaja en los EE. UU.",
    the: "the",
    more: "Más guías de viaje en",
    travelGuidesToExplore: "para explorar",
    looksLikeYoureIn: "Parece que está en XX en este momento",
    wantToSee: "¿Desea ver nuestra Guía de viajes para XX?",
    wishYouWereHere: "Desearía estar aquí",
    nextTime:
      "Parece que no está en XX en este momento. La próxima vez que esté de visita, asegúrese de consultar la Guía de viajes y ver qué hay cerca.",
    acceptedPaymentsIn: "Pagos aceptados en",
    nilson: "Según la edición de febrero de 2024 del Informe Nilson.",
    honolulu: {
      shortGreeting: "Aloha!",
      marqueeHeadline: "Aloha! Let's explore Honolulu.",
      mobileHeadline: "Aloha Honolulu!",
      travelGuideHeadline:
        "The top Honolulu hot spots our cardholders visit most",
      travelGuideBody:
        "And we think you’ll see why. These are some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town, and everywhere you see these logos.",
    },
    losangeles: {
      shortGreeting: "¡Hey!",
      marqueeHeadline: "¡Hey! Exploremos Los Ángeles.",
      mobileHeadline: "¡Hey! Los Ángeles",
      travelGuideHeadline:
        "Los destinos imperdibles de Los Ángeles según los titulares de tarjetas",
      travelGuideBody:
        "Y sabemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    newyork: {
      shortGreeting: "¡Hi!",
      marqueeHeadline: "¡Hi! Exploremos Nueva York.",
      mobileHeadline: "¡Hi! New York City",
      travelGuideHeadline:
        "La mejor lista de lugares de moda de Nueva York, según los titulares de tarjetas",
      travelGuideBody:
        "Ysabemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos:",
    },
    miami: {
      shortGreeting: "¡Hola!",
      marqueeHeadline: "¡Hola! Exploremos Miami.",
      mobileHeadline: "¡Hola! Miami",
      travelGuideHeadline:
        "Los lugares imperdibles de Miami, según los titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    sanjuan: {
      shortGreeting: "¡Hola!",
      marqueeHeadline: "¡Hola! Exploremos San Juan.",
      mobileHeadline: "¡Hola! San Juan",
      travelGuideHeadline:
        "Los lugares más populares de San Juan, según los titulares de tarjetas",
      travelGuideBody:
        "Y sabemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    dubai: {
      shortGreeting: "¡Salam!",
      marqueeHeadline: "¡Salam! Exploremos Dubái.",
      mobileHeadline: "¡Salam! Dubái",
      travelGuideHeadline:
        "Vea los principales destinos en Dubái que más visitan los titulares de tarjetas",
      travelGuideBody:
        "Y sabemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    tokyo: {
      shortGreeting: "こんにちは",
      marqueeHeadline: "¡Konnichiwa! Exploremos Tokio.",
      mobileHeadline: "¡Konnichiwa! Tokio",
      travelGuideHeadline:
        "Los principales destinos de Tokio, según los titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    seoul: {
      shortGreeting: "여보!",
      marqueeHeadline: "¡An nyeong ha seyo! Exploremos Seúl.",
      mobileHeadline: "¡An nyeong ha seyo! Seoul",
      travelGuideHeadline:
        "Los titulares de tarjetas dicen que estas son las paradas imperdibles en Seúl",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    vancouver: {
      shortGreeting: "Hey there!",
      marqueeHeadline: "¡Hey there! Exploremos Vancouver.",
      mobileHeadline: "¡Hey there! Vancouver",
      travelGuideHeadline:
        "Los lugares favoritos de nuestros titulares de tarjetas en Vancouver",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    toronto: {
      shortGreeting: "Hey there!",
      marqueeHeadline: "¡Hey there! Exploremos Toronto.",
      mobileHeadline: "¡Hey there! Toronto",
      travelGuideHeadline:
        "Los principales lugares de viaje de Toronto que nuestros titulares de tarjetas siguen visitando",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    bangkok: {
      shortGreeting: "สวัสดี!",
      marqueeHeadline: "¡Sawasdee! Exploremos Bangkok.",
      mobileHeadline: "¡Sawasdee! Bangkok",
      travelGuideHeadline:
        "Los mejores destinos de Bangkok según los titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    london: {
      shortGreeting: "¡Hello!",
      marqueeHeadline: "¡Hello! Exploremos Londres.",
      mobileHeadline: "¡Hello Londres!",
      travelGuideHeadline:
        "La mejor lista de destinos de Londres de nuestros titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    berlin: {
      shortGreeting: "¡Hallo!",
      marqueeHeadline: "¡Hallo! Exploremos Berlín.",
      mobileHeadline: "¡Hallo! Berlín",
      travelGuideHeadline: "Lo mejor de Berlín según los titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    istanbul: {
      shortGreeting: "Merhaba!",
      marqueeHeadline: "¡Merhaba! Exploremos Estambul.",
      mobileHeadline: "¡Merhaba! Estambul",
      travelGuideHeadline:
        "Los principales destinos de Estambul que más visitan nuestros titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    lisbon: {
      shortGreeting: "¡Olá!",
      marqueeHeadline: "¡Olá! Exploremos Lisboa.",
      mobileHeadline: "¡Olá! Lisboa",
      travelGuideHeadline:
        "Los principales lugares de viaje que a los titulares de tarjetas aman en Lisboa",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    cancun: {
      shortGreeting: "¡Hola!",
      marqueeHeadline: "¡Hola! Exploremos Cancún.",
      mobileHeadline: "¡Hola! Cancún",
      travelGuideHeadline:
        "Los lugares imperdibles de Cancún según los titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    rome: {
      shortGreeting: "¡Salvē!",
      marqueeHeadline: "¡Salvē! Exploremos Roma.",
      mobileHeadline: "¡Salvē! Roma",
      travelGuideHeadline:
        "Los principales lugares de viaje según los titulares de tarjetas en Roma",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    barcelona: {
      shortGreeting: "¡Hola!",
      marqueeHeadline: "¡Hola! Exploremos Barcelona.",
      mobileHeadline: "¡Hola! Barcelona",
      travelGuideHeadline:
        "Lo mejor de Barcelona según los titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
      calloutTitle: "Explore más de Barcelona",
      calloutCopy:
        "Encuentre itinerarios y consejos de viaje para su aventura en Barcelona.",
      calloutCTA: "Vamos",
    },
    paris: {
      shortGreeting: "¡Salut!",
      marqueeHeadline: "¡Salut! Exploremos París.",
      mobileHeadline: "¡Salut! París",
      travelGuideHeadline:
        "Lo mejor de los lugares de moda Parisinos que a nuestros titulares de tarjetas les encantan",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    singapore: {
      shortGreeting: "你好",
      marqueeHeadline: "¡Ni hao! Exploremos Singapur.",
      mobileHeadline: "¡Ni hao! Singapur",
      travelGuideHeadline:
        "Los mejores lugares de Singapur que más visitan nuestros titulares de tarjetas",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    seville: {
      shortGreeting: "¡Hola!",
      marqueeHeadline: "¡Hola! Exploremos Sevilla.",
      mobileHeadline: "¡Hola! Sevilla",
      travelGuideHeadline:
        "Los destinos de Sevilla que nuestros titulares de tarjetas visitan una y otra vez",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    madrid: {
      shortGreeting: "¡Hola!",
      marqueeHeadline: "¡Hola! Exploremos Madrid",
      mobileHeadline: "¡Hola! Madrid",
      travelGuideHeadline:
        "Los lugares más visitados por nuestros titulares de tarjetas en Madrid",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
    doha: {
      shortGreeting: "¡Salam alaykum!",
      marqueeHeadline: "¡Salam alaykum! Exploremos Doha.",
      mobileHeadline: "¡Salam alaykum! Doha",
      travelGuideHeadline:
        "Los destinos de Doha que nuestros titulares de tarjetas siguen visitando",
      travelGuideBody:
        "Y creemos que notará por qué. Estos son algunos de los lugares más populares para comer, comprar, alojarse y explorar, y esto es solo el comienzo. Se acepta su tarjeta en miles de lugares más en toda la ciudad y en cualquier lugar donde vea estos logos.",
    },
  },
};

export default cityViewCopy;
