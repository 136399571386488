import React from "react";
import { styled } from "@mui/material/styles";
import { Box, useTheme, Typography, useMediaQuery } from "@mui/material/";

import TravelGuidesLogo from "../CountryView/TravelGuidesLogo";
import Container from "../Layout/Container";
import t__ from "../../util/lang/util";
import intro from "../../util/lang/intro";
import GuideSelect from "./GuideSelect";
import marqueeImg from "./images/lifestyle-image@2x.png";
import marqueeImgDefault from "./images/Lifestyle_Image_L.png";
import marqueeImgMobile from "./images/lifestyle-image-mobile.png";
import marqueeImgMobileDefault from "./images/Lifestyle_Image_S.png";
import isDefaultBrand from "../../util/isDefaultBrand";

const PREFIX = "IntroMarquee";

const classes = {
  marquee: `${PREFIX}-marquee`,
  marqueeHeader: `${PREFIX}-marqueeHeader`,
  marqueeImg: `${PREFIX}-marqueeImg`,
  marqueeContent: `${PREFIX}-marqueeContent`,
  travelBox: `${PREFIX}-travelBox`,
  marqueeMobileImg: `${PREFIX}-marqueeMobileImg`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.marquee}`]: {
    alignItems: "flex-start",
    flexDirection: "column",
    height: 700,
    backgroundColor: isDefaultBrand(theme)
      ? theme.palette.primary.main
      : theme.palette.brand.introMarqueeBg,
    color: isDefaultBrand(theme)
      ? theme.palette.common.white
      : theme.palette.brand.text,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      height: "calc(100vw / 2.5)",
      alignItems: "center",
      maxHeight: "500px",
    },
  },

  [`& .${classes.marqueeHeader}`]: {
    zIndex: 1,
    position: "relative",
    lineHeight: "38px",

    [theme.breakpoints.up("xl")]: {
      fontSize: "48px",
      lineHeight: "54px",
    },
  },

  [`& .${classes.marqueeImg}`]: {
    height: "100%",
    position: "relative",
    right: -1,
  },

  [`& .${classes.marqueeContent}`]: {
    backgroundColor: isDefaultBrand(theme)
      ? theme.palette.secondary.light
      : theme.palette.brand.primaryBg,
    height: 165,
    width: "100%",
    textAlign: "center",
    "&:after": {
      position: "absolute",
      zIndex: 0,
      bottom: 250,
      left: 0,
      content: `"''"`,
      display: "block",
      height: 300,
      width: "100%",
      borderBottomRightRadius: "50vw",
      borderBottomLeftRadius: "50vw",
      backgroundColor: isDefaultBrand(theme)
        ? theme.palette.secondary.light
        : theme.palette.brand.primaryBg,
    },
    [theme.breakpoints.up("md")]: {
      flex: 1,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: "250px",
      borderBottomRightRadius: "250px",
      height: "100%",
      width: "auto",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(7),
      "&:after": {
        display: "none",
      },
    },
  },

  [`& .${classes.travelBox}`]: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },

  [`& .${classes.marqueeMobileImg}`]: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    height: 250,
    background: `url(${
      isDefaultBrand(theme) ? marqueeImgMobileDefault : marqueeImgMobile
    }) 0 0 no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
  },
}));

const IntroMarquee = ({ cities, countryData }) => {
  const theme = useTheme();

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const citiesWithCountry = cities.map((c) => {
    const countryEntry = countryData.find((a) => a.cca3 === c.country);
    return {
      ...c,
      countryFull: countryEntry.translated_name ?? countryEntry.common_name,
    };
  });

  return (
    <StyledBox display="flex" width="100%" className={classes.marquee}>
      <Container
        style={{
          background: "transparent",
          transform: isMediumScreen ? "translate(-50%, -50%)" : "",
        }}
        position="absolute"
        top={isMediumScreen ? "50%" : theme.spacing(15)}
        left={isMediumScreen ? "50%" : 0}
        zIndex={2}
      >
        <Box
          data-aos="fade-left"
          data-aos-delay="400"
          data-aos-duration="500"
          data-aos-once="true"
          paddingLeft={isMediumScreen ? theme.spacing(20) : ""}
        >
          <Box className={classes.travelBox}>
            <TravelGuidesLogo
              borderBottom={`solid 2px ${
                isDefaultBrand(theme)
                  ? theme.palette.common.white
                  : theme.palette.brand.text
              }`}
              variant="h3"
            />
          </Box>
          <Box
            mt={10}
            mb={4}
            maxWidth={{ xs: "320px", md: "450px", xl: "600px" }}
            marginX={isMediumScreen ? "0" : "auto"}
          >
            <Typography
              component="h1"
              variant="subtitle1"
              className={classes.marqueeHeader}
              align={isMediumScreen ? "left" : "center"}
            >
              {t__(theme.lang, intro, "marqueeHeader")}
            </Typography>
          </Box>
          <Box
            display={!isMediumScreen ? "flex" : "block"}
            justifyContent="center"
          >
            <GuideSelect cities={citiesWithCountry} />
          </Box>
        </Box>
      </Container>

      <Box
        className={classes.marqueeContent}
        style={{
          color: isDefaultBrand(theme)
            ? theme.palette.common.white
            : theme.palette.brand.text,
        }}
      ></Box>
      {isMediumScreen ? (
        <Box className={classes.marqueeImg}>
          {isDefaultBrand(theme) ? (
            <img
              src={marqueeImgDefault}
              className={classes.marqueeImg}
              alt=""
            />
          ) : (
            <img src={marqueeImg} className={classes.marqueeImg} alt="" />
          )}
        </Box>
      ) : (
        <Box className={classes.marqueeMobileImg} />
      )}
    </StyledBox>
  );
};

export default IntroMarquee;
