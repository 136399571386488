const countryView = {
  en: {
    generalHeadline: "Explore top destinations around the world",
    bannerHeadlines: [
      "Experience everywhere your card can take you",
      "Accepted across millions of places around the world",
      "Travel with confidence knowing your card is part of a global network",
    ],
    regionModules: {
      asiaPacific: {
        headline: "Asia Pacific",
        description:
          "Use your card almost everywhere in Japan as well as top travel destinations across Asia and Pacific Islands, including India, China, Singapore, Australia and more.",
        cta: "Traveling to Japan? ",
        links: [
          {
            city: "Tokyo",
            copy: "See the Tokyo Travel Guide",
          },
        ],
      },
      europe: {
        headline: "Europe",
        description:
          "You can use your card across some of Europe's most popular destinations, including Spain, Germany, the UK, Italy, Portugal and France.",
        cta: "Plus, explore travel guides for ",
        links: [
          {
            city: "Barcelona",
            copy: "Barcelona",
          },
          {
            city: "London",
            copy: "London",
          },
          {
            city: "Rome",
            copy: "Rome",
          },
          {
            city: "Paris",
            copy: "Paris",
          },
          {
            city: "Lisbon",
            copy: "and Lisbon",
          },
        ],
      },
      middleEastAfrica: {
        headline: "Middle East & Africa",
        description:
          "Use your card in the UAE, Egypt, Morocco and South Africa. With destinations in the Middle East & Africa expanding rapidly, you can use your card in more and more places here.",
        cta: "Explore recommended places to visit with the ",
        links: [
          {
            city: "Dubai",
            copy: "Dubai Travel Guide",
          },
        ],
      },
      americas: {
        headline: "The Americas",
        description:
          "You can use your card across Canada, Mexico and the U.S., as well as in popular South American travel spots like Peru, Uruguay, Argentina, Chile and more.",
        cta: "Browse travel guides for ",
        links: [
          {
            city: "Cancun",
            copy: "Cancun",
          },
          {
            city: "Vancouver",
            copy: "Vancouver",
          },
          {
            city: "Toronto",
            copy: "Toronto",
          },
        ],
      },
    },
  },
  es: {
    generalHeadline: "Explorá los destinos principales de todo el mundo",
    bannerHeadlines: [
      "Disfrute donde quiera que su tarjeta pueda llevarlo",
      "Aceptado en millones de lugares alrededor del mundo",
      "Viaje con confianza sabiendo que su tarjeta es parte de una red global",
    ],
    regionModules: {
      asiaPacific: {
        headline: "Asia Pacífico",
        description:
          "Use su tarjeta en casi todas partes de Japón, así como en los principales destinos de viaje en Asia e Islas del Pacífico, incluidos India, China, Singapur, Australia y más.",
        cta: "¿Viaja a Japón?",
        links: [
          {
            city: "Tokyo",
            copy: "Consulte la Guía de viajes de Tokio",
          },
        ],
      },
      europe: {
        headline: "Europa",
        description:
          "Puede utilizar su tarjeta en algunos de los destinos más populares de Europa, incluidos España, Alemania, el Reino Unido, Italia, Portugal y Francia.",
        cta: "Además, explore las guías de viaje para ",
        links: [
          {
            city: "Barcelona",
            copy: "Barcelona",
          },
          {
            city: "London",
            copy: "Londres",
          },
          {
            city: "Rome",
            copy: "Roma",
          },
          {
            city: "Paris",
            copy: "París",
          },
          {
            city: "Lisbon",
            copy: "y Lisboa",
          },
        ],
      },
      middleEastAfrica: {
        headline: "Medio Oriente y África",
        description:
          "Use su tarjeta en los EAU, Egipto, Marruecos y Sudáfrica. Con destinos en Oriente Medio y África que se expanden rápidamente, puede usar su tarjeta en cada vez más lugares.",
        cta: "Explore los lugares recomendados para visitar con la ",
        links: [
          {
            city: "Dubai",
            copy: "Guía de viajes de Dubái",
          },
        ],
      },
      americas: {
        headline: "América",
        description:
          "Puede usar su tarjeta en Canadá, México y los EE.UU., así como en los lugares de viaje mas populares de América del Sur como Perú, Uruguay, Argentina, Chile y más.",
        cta: "Explore guías de viaje para  ",
        links: [
          {
            city: "Cancun",
            copy: "Cancún",
          },
          {
            city: "Vancouver",
            copy: "Vancouver",
          },
          {
            city: "Toronto",
            copy: "Toronto",
          },
        ],
      },
    },
  },
};

export default countryView;
