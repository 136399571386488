import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Link,
  useMediaQuery,
} from "@mui/material/";
import t__ from "../../util/lang/util";
import footer from "../../util/lang/footer";
import { ReactComponent as TwitterIcon } from "../Icons/twitter-icon.svg";
import { ReactComponent as FacebookIcon } from "../Icons/facebook-icon.svg";
import { ReactComponent as LinkedinIcon } from "../Icons/linkedin-icon.svg";
import { ReactComponent as YoutubeIcon } from "../Icons/youtube-icon.svg";
import { ReactComponent as UpArrow } from "../Icons/upward-arrow-icon-white.svg";
import DGNIcon from "../Icons/dgn-white@2x.png";
import Container from "../Layout/Container";
import isDefaultBrand from "../../util/isDefaultBrand";
import { visuallyHidden } from "@mui/utils";

const PREFIX = "Footer";

const classes = {
  footerWrapper: `${PREFIX}-footerWrapper`,
  footerLinkList: `${PREFIX}-footerLinkList`,
  footerIconList: `${PREFIX}-footerIconList`,
  footerLink: `${PREFIX}-footerLink`,
  homeLink: `${PREFIX}-homeLink`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.footerWrapper}`]: {
    backgroundColor: isDefaultBrand(theme)
      ? theme.palette.primary.main
      : theme.palette.brand.footerBg,
    padding: `${theme.spacing(10)} 0`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(10)} 0`,
    },
  },

  [`& .${classes.footerLinkList}`]: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      maxWidth: 300,
    },
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    padding: 0,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      textAlign: "left",
    },
    "& li": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      color: theme.palette.common.white,
      [theme.breakpoints.up("md")]: {
        flexBasis: "auto",
        padding: `0 ${theme.spacing(4)}`,
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
  },

  [`& .${classes.footerIconList}`]: {
    display: "flex",
    listStyle: "none",
    margin: 0,
    padding: `${theme.spacing(8)} 0 0 0`,
    justifyContent: "center",
    "& li": {
      padding: `0 ${theme.spacing(1.5)}`,
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        paddingRight: 0,
      },
    },
    "& a:focus": {
      outline: `1px solid ${theme.palette.common.white}`,
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      padding: 0,
    },
    [theme.breakpoints.down("md")]: {
      "& img": {
        width: 35,
        height: 35,
      },
      "& li": {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    "& a": {
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        color: theme.palette.background.light,
      },
    },
  },

  [`& .${classes.footerLink}`]: {
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    "&:visited": {
      color: theme.palette.common.white,
    },
    "& svg": {
      height: 12,
      width: 12,
      marginLeft: 4,
    },
  },

  [`& .${classes.homeLink}`]: {
    display: "inline-block",
    "&:focus": {
      outline: `1px solid ${theme.palette.common.white}`,
    },
  },
}));

const Icons = {
  twitter: <TwitterIcon />,
  facebook: <FacebookIcon />,
  linkedIn: <LinkedinIcon />,
  youtube: <YoutubeIcon />,
};

const Footer = () => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledBox className={classes.footerWrapper}>
      <Container
        bgcolor="inherit"
        style={{ color: theme.palette.common.white }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" color="inherit" component="p">
              {t__(theme.lang, footer, "merchants")}
            </Typography>
            <Box
              marginTop={12}
              marginBottom={8}
              align={fullScreen ? "left" : "center"}
            >
              <Link href={theme.footer.homeLink} className={classes.homeLink}>
                <img src={DGNIcon} width={92} height={33} alt="" />
              </Link>
            </Box>
          </Grid>
          <Grid xs={12} md={9} item>
            <Box
              display="flex"
              justifyContent={fullScreen ? "flex-start" : "center"}
            >
              <ul className={classes.footerLinkList}>
                {theme.footer.commonLinks.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        href={item.href}
                        className={classes.footerLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography variant="body2">
                          <b>{item.title}</b>
                        </Typography>
                        <UpArrow />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <ul className={classes.footerIconList}>
              {Object.entries(theme.footer.socialMedia).map(([key, value]) => {
                return (
                  <li key={key}>
                    <Link href={value}>
                      {Icons[key]}
                      <Typography style={visuallyHidden}>
                        {t__(theme.lang, footer, `${key}Message`)}
                      </Typography>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Grid>

          <Box
            marginTop={fullScreen ? 0 : 10}
            display="flex"
            width="100%"
            justifyContent={fullScreen ? "flex-start" : "center"}
          >
            <Typography variant="h5" color="inherit" component="span">
              © {new Date().getFullYear()}{" "}
              {t__(theme.lang, footer, "copyright")}
            </Typography>
          </Box>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export default Footer;
