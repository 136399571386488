import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  useTheme,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material/";
import { ReactComponent as arrowDownIcon } from "../Icons/chevron-down.svg";
import { useHistory } from "react-router-dom";
import t__ from "../../util/lang/util";
import intro from "../../util/lang/intro";
import isDefaultBrand from "../../util/isDefaultBrand";
import trackLocationChange from "../../util/trackLocationChange";

const PREFIX = "GuideSelect";

const classes = {
  formControl: `${PREFIX}-formControl`,
  menuItem: `${PREFIX}-menuItem`,
  searchButton: `${PREFIX}-searchButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    width: 290,
    marginTop: theme.spacing(5),
    position: "relative",
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(15),
    "@media (min-width: 375px)": {
      width: 360,
      marginLeft: -20,
      marginRight: -20,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
    "& fieldset": {
      border: "none",
      borderRadius: "0",
      marginRight: theme.spacing(13),
    },
  },
  [`& .${classes.searchButton}`]: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    padding: 0,
    paddingRight: 40,
    border: `1px solid ${theme.palette.borders.light}`,
    borderRadius: theme.spacing(15),
    textAlign: "left",
    boxShadow: "none",
    width: 290,
    "@media (min-width: 375px)": {
      width: 360,
      fontSize: theme.typography.body1.fontSize,
      paddingRight: 0,
    },
    "&:hover fieldset": {
      borderColor: `${theme.palette.borders.light} !important`,
    },
    "&:after": {
      content: "''",
      display: "block",
      width: 48,
      height: 48,
      borderRadius: 48,
      position: "absolute",
      background: isDefaultBrand(theme)
        ? theme.palette.primary.light
        : theme.palette.brand.guideSelectBg,
      right: 6,
      zIndex: 1,
    },

    [theme.breakpoints.down("md")]: {
      "& img": {
        position: "relative",
        left: "-2px",
      },
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: `${theme.palette.borders.light}`,
    },
    "&:hover .MuiSelect-select": {
      borderRadius: theme.spacing(15),
    },
    "& .MuiSelect-select": {
      outline: "none",
      minHeight: "auto",
      lineHeight: "normal",
      height: theme.spacing(15),
      display: "flex",
      alignItems: "center",
      padding: "0 20px",
      borderRadius: theme.spacing(15),
      position: "relative",
      zIndex: 2,
      whiteSpace: "unset",
      "& p": {
        textAlign: "center",
      },
      "@media (min-width: 375px)": {
        whiteSpace: "nowrap",
        "& p": {
          textAlign: "left",
        },
      },
      "&:hover, &:focus": {
        textDecoration: "underline",
        color: theme.palette.secondary.main,
      },
    },
    "& .MuiSelect-icon": {
      right: 20,
      top: 20,
      background: isDefaultBrand(theme)
        ? theme.palette.primary.light
        : theme.palette.brand.guideSelectBg,
      width: theme.spacing(5),
      borderRadius: theme.spacing(12),
      color: theme.palette.common.white,
      position: "absolute",
      zIndex: 3,
    },
  },
}));

const MItem = styled(MenuItem)(({ theme }) => ({
  [`&.${classes.menuItem}`]: {
    display: "flex",
    flexWrap: "wrap",
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    "&.Mui-disabled": {
      borderBottom: `1px solid ${theme.palette.borders.light}`,
      opacity: 1,
      marginBottom: theme.spacing(2),
      background: `#fff !important`,
    },
    "& p": {
      width: "100%",
    },
  },
}));

export default function GuideSelect({ cities, ...props }) {
  const history = useHistory();

  const theme = useTheme();
  const [city, setCity] = useState("");

  const handleChange = (event) => {
    setCity(event.target.value);
    history.push(
      `/guides/${event.target.value}${
        !isDefaultBrand(theme) ? `/?brand=${theme.brand}` : ``
      }`
    );
    trackLocationChange();
  };

  return (
    <Root>
      <FormControl className={classes.formControl} {...props}>
        <Select
          MenuProps={{
            sx: { height: 450, mt: "-90px" },
          }}
          className={classes.searchButton}
          variant="outlined"
          IconComponent={arrowDownIcon}
          id="demo-controlled-open-select"
          displayEmpty
          inputProps={{
            "aria-label": t__(theme.lang, intro, "dropdown"),
          }}
          value={city}
          onChange={handleChange}
        >
          <MItem value="" disabled className={classes.menuItem}>
            <Typography variant="body1" color="secondary">
              <b>{t__(theme.lang, intro, "dropdown")}</b>
            </Typography>
          </MItem>
          {cities.map((city, index) => (
            <MItem className={classes.menuItem} key={index} value={city.route}>
              <Typography variant="body2">
                <b>{city.translated_city}</b>
              </Typography>
              <Typography variant="h6">{city.countryFull}</Typography>
            </MItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
}

GuideSelect.propTypes = {
  cities: PropTypes.array,
};
