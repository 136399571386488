import React, { useRef } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material/";
import countryView from "../../../util/lang/countryView";
import dgnLogo from "../../Icons/DGN_AcceptanceMark_FC_Hrz_RGB.png";
import dinersLogo from "../../Icons/asset-decal-diner@2x.png";
import "../../../util/theme/slick.css";
import "../../../util/theme/slick-theme.css";
import Slider from "react-slick";
import video1Img from "./images/video1_tarynElliot_pexels.jpg";
import video2Img from "./images/video2_cottonBro_pexels.jpg";
import video3Img from "./images/video3_peterFowler_pexels.jpg";
import isDefaultBrand from "../../../util/isDefaultBrand";

const BannerTiles = () => {
  const theme = useTheme();
  const slider = useRef(null);
  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const headlines = countryView[theme.lang].bannerHeadlines;

  const banners = [
    {
      headline: headlines[0],
      img: video1Img,
    },
    {
      headline: headlines[1],
      img: video2Img,
    },
    {
      headline: headlines[2],
      img: video3Img,
    },
  ];

  return (
    <Box position="relative">
      <Slider
        arrows={false}
        autoplay={true}
        dots={false}
        infinite={true}
        speed={4800}
        slidesToShow={1}
        slidesToScroll={1}
        ref={slider}
        fade={true}
        pauseOnHover={false}
      >
        {banners.map((b, ind) => (
          <div key={`banner${ind}`}>
            <Box display="flex" flexDirection={fullScreen ? "row" : "column"}>
              <Box
                height={fullScreen ? 386 : 232}
                width={fullScreen ? "50%" : "100%"}
                display="flex"
                flexDirection="column"
                bgcolor={
                  isDefaultBrand(theme)
                    ? "secondary.light"
                    : theme.palette.brand.primaryBg
                }
                position="relative"
                justifyContent={fullScreen ? "center" : "flex-start"}
              >
                <Box
                  maxWidth={fullScreen ? 350 : 280}
                  marginX="auto"
                  marginTop={fullScreen ? 0 : 4}
                  marginBottom={fullScreen ? 6 : 0}
                  minHeight={fullScreen ? 110 : 1}
                >
                  <Typography
                    variant="subtitle2"
                    component="h3"
                    align="center"
                    style={{
                      color: isDefaultBrand(theme)
                        ? theme.palette.common.white
                        : theme.palette.brand.text,
                    }}
                  >
                    <b>{b.headline}</b>
                  </Typography>
                </Box>
                <Box
                  justifyContent="center"
                  position={fullScreen ? "relative" : "absolute"}
                  bottom={fullScreen ? "auto" : theme.spacing(6)}
                  width="100%"
                  display="flex"
                >
                  {isDefaultBrand(theme) || theme.brand === "cabal" ? (
                    <>
                      <Box mr={3}>
                        <img
                          src={dinersLogo}
                          alt="Diner's Club International"
                          width={74}
                        />
                      </Box>
                      <Box ml={3}>
                        <img src={dgnLogo} alt="Discover Card" width={87} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box mr={3}>
                        <img
                          src={theme.palette.brand.icon}
                          alt="Discover Card"
                          height={56}
                        />
                      </Box>
                      <Box ml={3}>
                        <img src={dgnLogo} alt="Discover Card" width={87} />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box
                overflow="hidden"
                height={fullScreen ? 386 : 232}
                width={fullScreen ? "50%" : "100%"}
                position="relative"
              >
                <Box
                  className="kenburns"
                  height={fullScreen ? 386 : 232}
                  width={"100% !important"}
                  style={{
                    backgroundImage: `url(${b.img})`,
                    backgroundSize: "cover",
                  }}
                ></Box>
                <svg xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <clipPath id="circleClip">
                      {fullScreen ? (
                        <path d="M194 196v190H1c105.525 0 191.27-84.69 193-190zm0-196v191C192.26 85.128 106.072 0 0 0h194z" />
                      ) : (
                        <path
                          d="M117 117.803V232H.603C64.244 232 115.957 181.099 117 117.803zM117 0v114.798C115.951 51.165 63.971 0 0 0h117z"
                          fill={
                            isDefaultBrand(theme)
                              ? "#23233F"
                              : theme.palette.brand.bannerTilesBg
                          }
                          fillRule="evenodd"
                        />
                      )}
                    </clipPath>
                  </defs>
                </svg>

                <Box
                  height={fullScreen ? 386 : 232}
                  width={fullScreen ? 194 : 117}
                  bgcolor={
                    isDefaultBrand(theme)
                      ? "#23233F"
                      : theme.palette.brand.bannerTilesBg
                  }
                  style={{
                    clipPath: `url("#circleClip")`,
                  }}
                  zIndex="1"
                  position="absolute"
                  right="0"
                  top="0"
                ></Box>
              </Box>
            </Box>
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default BannerTiles;
