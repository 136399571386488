import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material/";
import t__ from "../../util/lang/util";
import AcceptanceMarks from "../AcceptanceMarks";
import countryDrawer from "../../util/lang/countryDrawer";

const AcceptanceMarkBox = ({ countryData, hasCreditCoverage, region }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      border={1}
      borderColor="borders.light"
      borderRadius={theme.spacing(3)}
      padding={mdScreen ? theme.spacing(10) : theme.spacing(5)}
      width="100%"
    >
      <Typography variant={mdScreen ? "h3" : "body1"}>
        <b>
          {t__(
            theme.lang,
            countryDrawer,
            `acceptanceMarkHeadline.${
              hasCreditCoverage ? "coverage" : "noCoverage"
            }`
          )}
        </b>
      </Typography>
      <Box mt={3} mb={3}>
        <Typography>
          {t__(
            theme.lang,
            countryDrawer,
            `${
              hasCreditCoverage
                ? "acceptanceATMcoverage"
                : `noAcceptanceATMcoverage.${region}`
            }`
          )}
        </Typography>
      </Box>
      <Typography>
        {hasCreditCoverage
          ? `${t__(theme.lang, countryDrawer, "aboveMarksCopy.coverage")} ${
              countryData.cca3 === "USA" ? "the" : ""
            } ${countryData.common_name}`
          : t__(
              theme.lang,
              countryDrawer,
              `aboveMarksCopy.noCoverage.${region}`
            )}
      </Typography>
      <AcceptanceMarks
        marks={countryData.credit_mark}
        marksStyles={{ marginTop: theme.spacing(3) }}
        style={{ flexWrap: "wrap" }}
      />
    </Box>
  );
};

AcceptanceMarkBox.propTypes = {
  countryData: PropTypes.object.isRequired,
  hasCreditCoverage: PropTypes.bool.isRequired,
  region: PropTypes.oneOf(["Europe", "Africa", "Asia", "America", "Antarctica"])
    .isRequired,
};

export default AcceptanceMarkBox;
