import React, { useContext } from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material/";

//translation imports
import countryView from "../../util/lang/countryView";
import acceptanceLevels from "../../util/lang/acceptanceLevels";
import countryDrawer from "../../util/lang/countryDrawer";
import cityViewCopy from "../../util/lang/cityViewCopy";
import common from "../../util/lang/common";
import t__ from "../../util/lang/util";
import { MapContext } from "../../contexts";
import CityTiles from "./CityTiles";
import RegionTiles from "./RegionTiles";
import BannerTiles from "./BannerTiles";
import TravelGuidesLogo from "./TravelGuidesLogo";
import Container from "../Layout/Container";
import AcceptanceMarkBox from "../AcceptanceMarkBox";
import AtmBox from "../AtmBox";

const CountryView = () => {
  const theme = useTheme();
  const { cities, selectedCountry, acceptanceData } = useContext(MapContext);
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const getRegion = (string) => {
    if (string.includes("Europe")) return "Europe";
    if (string.includes("Africa" || "Middle East")) return "Africa";
    if (string.includes("Asia")) return "Asia";
    if (string.includes("America")) return "America";
    if (string.includes("Antarctica")) return "Antarctica";
  };

  //user has clicked on a country
  if (selectedCountry?.code) {
    const countryData = acceptanceData.find(
      (a) => a.cca3 === selectedCountry.code
    );

    const hasCreditCoverage = countryData.credit_level !== "NO COVERAGE";
    const region = getRegion(selectedCountry.region);
    const cityGuides = cities.filter((c) => c.country === selectedCountry.code);

    return (
      <>
        <Container pt={10}>
          <Typography variant="h2" component="h1">
            <b>
              {t__(theme.lang, common, "acceptanceIn")}{" "}
              {countryData.cca3 === "USA"
                ? theme.lang === "en"
                  ? "the "
                  : "los "
                : ""}
              {countryData.translated_name ?? countryData.common_name}
            </b>
          </Typography>
          <Box mt={3} mb={10}>
            <Typography>
              {countryDrawer[theme.lang].priorityCountries[countryData.cca3] ??
                acceptanceLevels[theme.lang].headlines[
                  countryData.credit_level
                ]}
            </Typography>
          </Box>

          {cityGuides.length > 0 && (
            <Box>
              {<TravelGuidesLogo />}
              <CityTiles citiesList={cityGuides} />
            </Box>
          )}

          <Box mt={10} mb={10}>
            <Grid container rowSpacing={6} columnSpacing={mdScreen ? 6 : 0}>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <AcceptanceMarkBox
                  countryData={countryData}
                  hasCreditCoverage={hasCreditCoverage}
                  region={region}
                />
              </Grid>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <AtmBox countryData={countryData} />
              </Grid>
            </Grid>
          </Box>
          {countryData.cca3 === "USA" && (
            <Box mb={10}>
              <Typography variant="body2" color="textSecondary">
                *{t__(theme.lang, cityViewCopy, "nilson")}
              </Typography>
            </Box>
          )}
        </Container>
      </>
    );
  } else {
    const defaultCities = [
      "Paris",
      "Barcelona",
      "New York",
      "Rome",
      "Istanbul",
      "Cancun",
    ];

    return (
      <>
        <Container pt={6}>
          <TravelGuidesLogo />

          <Box mt={3} mb={15}>
            <Typography variant="h2" component="h1">
              <b>{t__(theme.lang, countryView, "generalHeadline")}</b>
            </Typography>
            <CityTiles
              citiesList={cities?.filter((c) => defaultCities.includes(c.city))}
            />
          </Box>
        </Container>

        <Box
          color="common.white"
          bgcolor={
            theme.brand === "default"
              ? "primary.main"
              : theme.palette.common.white
          }
        >
          <BannerTiles />
        </Box>

        <Box bgcolor="background.light">
          <Container paddingY={15} bgcolor="background.light">
            <Box maxWidth={theme.containerMaxWidth} marginX="auto">
              <Grid container spacing={5}>
                <RegionTiles cities={cities} />
              </Grid>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
};

export default CountryView;
