import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material/";
import isDefaultBrand from "../../../util/isDefaultBrand";
import t__ from "../../../util/lang/util";
import cityViewCopy from "../../../util/lang/cityViewCopy";

import bgImage from "./images/image-right-large@2x.jpg";

const PREFIX = "MerchantSpotlight";

const classes = {
  simpleBtn: `${PREFIX}-simpleBtn`,
  gridContainer: `${PREFIX}-gridContainer`,
  rightContainer: `${PREFIX}-rightContainer`,
  leftContainer: `${PREFIX}-leftContainer`,
  maxWidth335: `${PREFIX}-maxWidth335`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.simpleBtn}`]: {
    fontSize: 14,
    borderRadius: 20,
    padding: "0px 16px !important",
    height: "36px !important",
    width: "100% !important",
    color: `${theme.palette.common.white} !important`,
    //minWidth: 335,
    backgroundColor: `${
      isDefaultBrand(theme)
        ? theme.palette.primary.light
        : theme.palette.primary.main
    }`,
    fontWeight: "bold",

    "&:hover, &:focus": {
      backgroundColor: `${
        isDefaultBrand(theme) ? "#E94D00" : theme.palette.brand.primaryBg
      }`,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 100,
      width: "auto !important",
    },
  },

  [`&.${classes.gridContainer}`]: {
    backgroundColor: theme.palette.background.light,
  },

  [`& .${classes.rightContainer}`]: {
    height: "100%",
    minHeight: "180px",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      minHeight: "215px",
    },
  },

  [`& .${classes.leftContainer}`]: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },

  [`& .${classes.maxWidth335}`]: {
    maxWidth: "335px",
  },
}));

const MerchantSpotlight = () => {
  const theme = useTheme();

  return (
    <StyledGrid container className={classes.gridContainer}>
      <Grid item xs={12} md={6} className={classes.leftContainer}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          py={{ xs: 10 }}
          px={{ xs: 5 }}
          textAlign={{ xs: "center" }}
          height="100%"
        >
          <Box className={classes.maxWidth335}>
            <Typography variant="h3" component="h4">
              <b>{t__(theme.lang, cityViewCopy, `barcelona.calloutTitle`)}</b>
            </Typography>

            <Box mt={3} mb={5}>
              <Typography>
                {t__(theme.lang, cityViewCopy, `barcelona.calloutCopy`)}
              </Typography>
            </Box>
          </Box>
          <Button
            className={classes.simpleBtn}
            sx={{ textTransform: "none" }}
            href="https://resources.dinersclub.com/barcelona"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t__(theme.lang, cityViewCopy, `barcelona.calloutCTA`)}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} className={classes.rightContainer}></Grid>
    </StyledGrid>
  );
};

export default MerchantSpotlight;
