import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  Grid,
  useMediaQuery,
  Link,
  useTheme,
} from "@mui/material/";
import Container from "../Layout/Container";

import t__ from "../../util/lang/util";
import intro from "../../util/lang/intro";
import cityViewCopy from "../../util/lang/cityViewCopy";
import CityCircle from "../CityCircle";
import { haversine_distance } from "../Map/mapmethods";
import isDefaultBrand from "../../util/isDefaultBrand";

const PREFIX = "TravelGuides";

const classes = {
  gridItem: `${PREFIX}-gridItem`,
  cityLink: `${PREFIX}-cityLink`,
  textWrap: `${PREFIX}-textWrap`,
  cityText: `${PREFIX}-cityText`,
  cityCircle: `${PREFIX}-cityCircle`,
  bugBg: `${PREFIX}-bugBg`,
  xButton: `${PREFIX}-xButton`,
  bug: `${PREFIX}-bug`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.gridItem}`]: {
    margin: "0px 20px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 15px",
    },
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },

  [`& .${classes.cityLink}`]: {
    transition: "all 0.2s",
    position: "relative",
    top: 0,
    color: theme.palette.primary.main,
    textDecoration: "none",

    "&:visited": {
      color: theme.palette.primary.main,
    },
    "&:hover, &:focus": {
      cursor: "pointer",
      textDecoration: "none",
      "&:first-child": {
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.5)",
        top: -2,
      },
      "&:last-child": {
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.5)",
        top: -2,
      },
    },
    "&:focus": {
      display: "block",
      outline: `1px solid ${theme.palette.secondary.light}`,
    },
  },

  [`& .${classes.textWrap}`]: {
    display: "flex",
    justifyContent: "center",
    margin: "2px auto 0",
    textAlign: "center",
    width: "100%",
  },

  [`& .${classes.cityText}`]: {
    background: theme.palette.common.white,
    padding: "2px 12px",
    textAlign: "center",
    borderRadius: 8,
    whiteSpace: "nowrap",
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.3)",
    transition: "all 0.4s",
    fontSize: 12,
    lineHeight: 1,
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },

  [`& .${classes.cityCircle}`]: {
    width: "68px",
    height: "68px",
    [theme.breakpoints.up("xl")]: {
      width: "90px",
      height: "90px",
    },
  },

  [`& .${classes.bugBg}`]: {
    display: "block",
    content: '""',
    height: 140,
    width: 140,
    top: -110,
    right: 16,
    position: "absolute",
    borderRadius: 140,
    background: "linear-gradient(135deg, #ec6b29 15%, #fcb116 85%)",
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.35)",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      top: -110,
    },
  },

  [`& .${classes.xButton}`]: {
    background: theme.palette.secondary.main,
    height: theme.spacing(6),
    width: theme.spacing(6),
    borderRadius: theme.spacing(6),
    minWidth: 1,
    position: "absolute",
    top: -106,
    right: theme.spacing(8),
    color: theme.palette.common.white,
    fontSize: theme.spacing(7),
    zIndex: 3,
    "&:hover, &:focus": {
      background: theme.palette.primary.main,
    },
    "& .MuiButton-label": {
      lineHeight: 0,
      paddingTop: 2,
    },
    [theme.breakpoints.up("md")]: {
      top: "-106px",
    },
  },

  [`& .${classes.bug}`]: {
    top: -106,
    right: 20,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      top: -106,
    },
    "& a": {
      color: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      "&:hover, &:focus": {
        background: theme.palette.background.light,
      },
    },
  },
}));

const CityItem = ({ city }) => {
  const theme = useTheme();

  return (
    <Link
      href={`/guides/${city.route}${
        !isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``
      }`}
      className={classes.cityLink}
    >
      <CityCircle cityImg={city.img} className={classes.cityCircle} />
      <div className={classes.textWrap}>
        <Typography className={classes.cityText} variant="body2">
          <b>{city.translated_city}</b>
        </Typography>
      </div>
    </Link>
  );
};

const CityGrid = ({ cities }) => {
  return (
    <Grid container justifyContent="center">
      {cities.map((city, index) => (
        <Grid
          data-aos="fade-up"
          data-aos-delay={index * 100}
          data-aos-duration="500"
          data-aos-once="true"
          key={index}
          item
          xs={3}
          md={1}
          className={classes.gridItem}
        >
          <CityItem city={city} />
        </Grid>
      ))}
    </Grid>
  );
};

const TravelGuides = ({ cities, selectedCities, countryData, ip }) => {
  const theme = useTheme();

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const filteredCities = cities.filter((city) =>
    selectedCities.includes(city.city)
  );
  const mobileCities = filteredCities.slice(0, 3);

  const [bug, setBug] = useState(false);

  useEffect(() => {
    if (ip) {
      const newLocation = {
        position: { lat: ip.lat, lng: ip.lon },
      };
      cities.forEach((c) => {
        const cityLocation = {
          position: { lat: c.lat, lng: c.long },
          city: c.city,
          route: c.route,
        };

        if (haversine_distance(cityLocation, newLocation) < 20) {
          setBug(cityLocation.city);
          return;
        }
      });
    }
  }, [cities, ip]);

  return (
    <Container>
      <StyledBox style={{ position: "relative" }}>
        {bug && (
          <>
            <Button className={classes.xButton} onClick={() => setBug(false)}>
              &times;
            </Button>
            <Box
              height={132}
              width={132}
              borderRadius="132px"
              className={classes.bug}
              bgcolor={theme.palette.common.white}
              position="absolute"
              zIndex={2}
              right={4}
              display="flex"
              justifyContent="center"
            >
              <Link
                href={`/guides/${cities.find((c) => c.city === bug).route}${
                  !isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``
                }`}
                underline="none"
              >
                <Typography
                  component="span"
                  align="center"
                  color="textPrimary"
                  style={{ fontSize: 18 }}
                >
                  <b>
                    {t__(
                      theme.lang,
                      cityViewCopy,
                      `${
                        cities.find((c) => c.city === bug).route
                      }.shortGreeting`
                    )}
                  </b>
                </Typography>
                <Typography variant="h5" component="span" align="center">
                  <b>{t__(theme.lang, intro, "bug").replace("XX", bug)}</b>
                </Typography>
              </Link>
            </Box>
            <Box component="span" className={classes.bugBg}></Box>
          </>
        )}
        <Box pt={{ xs: 10, md: 20 }} pb={{ xs: 10, md: 15 }}>
          <Box mb={4} position="relative">
            <Typography align="center" component="h2" variant="subtitle2">
              <b>{t__(theme.lang, intro, "travelGuidesHeader")}</b>
            </Typography>
          </Box>

          <Box
            mt={{ xs: 8, md: 12 }}
            maxWidth={{ sm: 400, md: "unset" }}
            marginX="auto"
          >
            <CityGrid cities={isMediumScreen ? filteredCities : mobileCities} />
          </Box>
          <Box
            maxWidth={{ sm: 400, md: 900 }}
            style={{ borderTop: `1px solid ${theme.palette.borders.light}` }}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            marginX="auto"
            mt={isMediumScreen ? 12 : 8}
            paddingTop={isMediumScreen ? 7 : 5}
            align="center"
          >
            <Box
              maxWidth={{ xs: 268, sm: 1000 }}
              marginX="auto"
              display={isMediumScreen ? "flex" : "block"}
            >
              <Typography>
                {t__(theme.lang, intro, "travelGuidesSub")}
              </Typography>
              <Link
                href={`/guides${
                  !isDefaultBrand(theme) ? `?brand=${theme.brand}` : ``
                }`}
                style={{ paddingLeft: theme.spacing(2) }}
              >
                <b>{t__(theme.lang, intro, "viewMap")}</b>
              </Link>
            </Box>
          </Box>
        </Box>
      </StyledBox>
    </Container>
  );
};

TravelGuides.propTypes = {
  cities: PropTypes.array.isRequired,
  countryData: PropTypes.array.isRequired,
  selectedCities: PropTypes.array.isRequired,
};

export default TravelGuides;
