import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material/";
import Container from "../Layout/Container";
import t__ from "../../util/lang/util";
import intro from "../../util/lang/intro";
import "../../util/theme/slick.css";
import "../../util/theme/slick-theme.css";
import Slider from "react-slick";
import bgDining from "./images/bg-dining.jpg";
import bgHotels from "./images/bg-hotels.jpg";
import bgTransportation from "./images/bg-transportation.jpg";
import bgRetail from "./images/bg-retail.jpg";
import bgAttraction from "./images/bg-attraction.jpg";
import CATEGORIES from "../../util/categories";

const PREFIX = "FeaturedMerchants";

const classes = {
  slides: `${PREFIX}-slides`,
  specific: `${PREFIX}-specific`,
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  firstBox: `${PREFIX}-firstBox`,
  secondBox: `${PREFIX}-secondBox`,
  simpleBtn: `${PREFIX}-simpleBtn`,
  last: `${PREFIX}-last`,
};
//I moved most of the style overrides for the slick slider to the slick.css file. Easier to target
const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.specific}`]: {
    "& .slick-slide": {
      backgroundColor: "#fff",
    },
    "& .slick-dots li": {
      width: 110,
      [theme.breakpoints.down("sm")]: {
        width: 100,
      },
    },
    "& .slick-next": {
      right: -10,
      top: 168,
    },
    "& .slick-prev": {
      left: "-10px",
      top: 168,
    },
  },

  [`& .${classes.secondBox}`]: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    [theme.breakpoints.down("sm")]: {
      borderTopRightRadius: 0,
    },
  },

  [`& .${classes.simpleBtn}`]: {
    fontSize: 14,
    borderRadius: 20,
    padding: "0px 16px !important",
    height: "36px !important",
    minWidth: 100,
    width: "auto !important",
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.primary.light,
    fontWeight: "bold",
    cursor: "pointer",

    "&:hover, &:focus": {
      backgroundColor: "#E94D00",
    },
  },

  [`& .${classes.last}`]: {
    "& .slick-dots li:last-child button": {
      [theme.breakpoints.down("sm")]: { opacity: 1 },
    },
    "& .slick-dots li:last-child button:before": {
      opacity: 1,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#63686b",
      },
    },
  },
}));

const images = {
  Dining: bgDining,
  Hotels: bgHotels,
  Transportation: bgTransportation,
  Retail: bgRetail,
  Attractions: bgAttraction,
};

const SpecificMerchants = ({ merchants }) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const slidesToShow = fullScreen ? 1 : 1.1;
  const [lastClass, setLastClass] = useState(false);

  return (
    <Box
      data-aos="fade-up"
      data-aos-delay={100}
      data-aos-duration="500"
      data-aos-once="true"
    >
      <Box className={lastClass ? classes.last : null}>
        <Box sx={{ mt: 5, mb: { xs: 12, md: 20 }, mx: "auto" }}>
          <Slider
            arrows={true}
            dots={true}
            infinite={false}
            speed={500}
            slidesToShow={slidesToShow}
            slidesToScroll={1}
            className={`${classes.slides} ${classes.specific}`}
            beforeChange={(current, next) => {
              next + 1.1 === merchants.length
                ? setLastClass(true)
                : setLastClass(false);
            }}
          >
            {merchants.map((m) => {
              return (
                <Box key={m.title} width="100%">
                  <Box
                    display="flex"
                    flexDirection={fullScreen ? "row" : "column"}
                  >
                    <Box
                      sx={{
                        minHeight: { xs: 224, md: 340 },
                        width: { xs: 1, md: 1 / 2 },
                        borderTopLeftRadius: 12,
                        borderBottomLeftRadius: 12,
                        border: `1px solid ${theme.palette.borders.light}`,
                        borderRight: "none",
                        bgcolor: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        [theme.breakpoints.down("sm")]: {
                          borderBottomLeftRadius: 0,
                          borderTopRightRadius: 12,
                          borderRight: `1px solid ${theme.palette.borders.light}`,
                          borderBottom: "none",
                        },
                        "& img": {
                          maxWidth: 200,
                          [theme.breakpoints.down("sm")]: {
                            maxWidth: 150,
                          },
                        },
                      }}
                      style={
                        m.cover
                          ? {
                              backgroundSize: "cover",
                              backgroundImage: `url(/featuredMerchants/${m.image})`,
                              backgroundPosition: "center center",
                            }
                          : {}
                      }
                    >
                      {!m.cover && (
                        <img
                          alt={m.alt ? m.alt : "logo"}
                          src={`/featuredMerchants/${m.image}`}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        width: { xs: 1, md: 1 / 2 },
                        minHeight: { xs: 100, md: 340 },
                        borderTopRightRadius: 12,
                        borderBottomRightRadius: 12,
                        bgColor: "text.primary",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        [theme.breakpoints.down("sm")]: {
                          borderTopRightRadius: 0,
                        },
                      }}
                    >
                      <Box
                        maxWidth={fullScreen ? "80%" : "100%"}
                        py={10}
                        px={5}
                        align={fullScreen ? "left" : "center"}
                      >
                        <Box mb={3}>
                          <Typography variant="h3">
                            <b>{m.title}</b>
                          </Typography>
                        </Box>
                        <Box mb={8}>
                          <Typography
                            variant="body1"
                            style={{ paddingBotton: "12px" }}
                          >
                            {m.copy}
                          </Typography>
                        </Box>
                        <Button
                          className={classes.simpleBtn}
                          href={m.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link To Merchant
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

const GenericMerchants = ({ merchants }) => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const slidesToShow = fullScreen ? 4 : mdScreen ? 3.5 : 2.25;
  const [lastClass, setLastClass] = useState(false);
  return (
    <Box
      mt={5}
      mb={{ xs: 12, md: 20 }}
      mx="auto"
      className={lastClass ? classes.last : null}
    >
      <Box mb={6}>
        <Typography align="center" component="h2" variant="subtitle2">
          <b>{t__(theme.lang, intro, `featuredMerchants.header`)}</b>
        </Typography>
      </Box>

      <Box
        data-aos="fade-right"
        data-aos-delay={500}
        data-aos-duration="500"
        data-aos-once="true"
      >
        <Slider
          arrows={true}
          dots={true}
          infinite={false}
          speed={500}
          slidesToShow={slidesToShow}
          slidesToScroll={fullScreen ? 4 : mdScreen ? 3 : 2}
          className={`${classes.slides}`}
          beforeChange={(current, next) => {
            next + 2.25 === merchants.length
              ? setLastClass(true)
              : setLastClass(false);
          }}
        >
          {merchants.map((m) => {
            const cat =
              m.category === "Dining"
                ? "restaurants"
                : m.category.toLowerCase();

            return (
              <Card
                sx={{
                  color: theme.palette.secondary.main,
                  position: "relative",
                  "&:hover h4": { textDecoration: "underline" },
                }}
                elevation={0}
                key={m.title}
              >
                <CardMedia
                  sx={{
                    height: theme.spacing(25),
                    backgroundColor: theme.palette.background.light,
                    position: "relative",
                    "&:after": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        "linear-gradient(to bottom, rgba(0, 0, 0, 0) -67%, rgba(0, 0, 0, 0.5) 133%)",
                      zIndex: 1,
                    },
                  }}
                  image={
                    m.img ? `/featuredMerchants/${m.img}` : images[m.category]
                  }
                >
                  <Box
                    color="white"
                    display="flex"
                    justifyContent="center"
                    position="absolute"
                    bottom={12}
                    width="100%"
                    zIndex="2"
                  >
                    {CATEGORIES.find((c) => c.mcc === cat).icon}

                    <Box pl={1}>
                      <Typography variant="body2">
                        <b>{t__(theme.lang, intro, cat)}</b>
                      </Typography>
                    </Box>
                  </Box>
                </CardMedia>
                <CardContent
                  style={{
                    padding: fullScreen ? theme.spacing(5) : theme.spacing(2.5),
                    paddingBottom: fullScreen ? theme.spacing(2.5) : 1.5,
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.text.primary,
                    minHeight: theme.spacing(25),
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography align="center" variant="body2">
                    <b>{m.title}</b>
                  </Typography>
                  <Box mt={2}>
                    <Typography
                      align="center"
                      variant="h5"
                      color="textSecondary"
                    >
                      {m.city}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

const FeaturedMerchants = () => {
  const theme = useTheme();
  const params = new URLSearchParams(window.location.search);

  const merchants = params.get("test")
    ? intro[theme.lang].featuredMerchantsAlt.merchants
    : intro[theme.lang].featuredMerchants.merchants;
  if (merchants.length === 0) {
    return null;
  }

  return (
    <StyledContainer style={{ position: "relative" }}>
      {merchants.length > 3 ? (
        <GenericMerchants merchants={merchants} />
      ) : (
        <SpecificMerchants merchants={merchants} />
      )}
    </StyledContainer>
  );
};

export default FeaturedMerchants;
