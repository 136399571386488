import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, useTheme, Link } from "@mui/material/";
import common from "../../util/lang/common";
import t__ from "../../util/lang/util";
import searchIcon from "../Icons/icon-mini-search.svg";
import pinIcon from "../Icons/city.svg";
import Container from "../Layout/Container/";
import { MapContext } from "../../contexts";
import AcceptanceMarks from "../AcceptanceMarks";
import isDefaultBrand from "../../util/isDefaultBrand";

const PREFIX = "Header";

const classes = {
  searchButton: `${PREFIX}-searchButton`,
  fieldText: `${PREFIX}-fieldText`,
  logos: `${PREFIX}-logos`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.searchButton}`]: {
    padding: 0,
    border: `1px solid ${theme.palette.borders.light}`,
    borderRadius: 18,
    textTransform: "none",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "225px",
      "& img": {
        position: "relative",
        left: "-2px",
      },
    },
    "@media (max-width: 320px)": {
      maxWidth: 180,
      minWidth: "unset",
    },
  },
}));

const Header = ({ cities }) => {
  const theme = useTheme();

  const { cityView, setSearchDrawer } = useContext(MapContext);
  return (
    <Container
      component="header"
      zIndex="2"
      position="fixed"
      height={62}
      top={0}
      paddingY={2}
      display="flex"
      justifyContent="center"
      boxShadow={`0px 6px 8px -7px ${theme.palette.borders.light}`}
      isWrapper={false}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        maxWidth={theme.containerMaxWidth}
      >
        <Link
          href={isDefaultBrand(theme) ? `/` : `/?brand=${theme.brand}`}
          className={classes.logos}
          sx={{
            display: "inline-block",
            "& img": {
              transition: "all .2s",
            },
            "&:focus, &:hover": {
              "& img": {
                boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.3)",
              },
            },
          }}
        >
          <AcceptanceMarks
            marks={isDefaultBrand(theme) ? ["DCI", "DN"] : theme.headerMarks}
            variant="xs"
          />
        </Link>
        <StyledButton
          className={classes.searchButton}
          onClick={() => setSearchDrawer(true)}
        >
          <Box paddingX={5}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              className={classes.fieldText}
              sx={{
                minWidth: 305,
                [theme.breakpoints.down("sm")]: {
                  maxWidth: 140,
                  minWidth: 140,
                },
                "@media (max-width: 320px)": {
                  maxWidth: 100,
                  minWidth: 1,
                },
              }}
            >
              {!cityView ? (
                t__(theme.lang, common, "searchDefault")
              ) : (
                <Box display="flex" alignItems="center" component="span">
                  <img
                    src={pinIcon}
                    alt="city icon"
                    width="14px"
                    height="14px"
                  />
                  <Box marginLeft={2} component="span">
                    {cities.find((c) => c.city === cityView).translated_city}
                  </Box>
                </Box>
              )}
            </Typography>
          </Box>
          <Box
            borderLeft={1}
            borderColor="borders.light"
            paddingY={2}
            paddingX={4}
            display="flex"
            alignItems="center"
          >
            <img src={searchIcon} alt="Search" height={20} width={20} />
          </Box>
        </StyledButton>
      </Box>
    </Container>
  );
};

export default Header;
