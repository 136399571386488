import React from "react";
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material/";

import dgnLogo from "../Icons/DGN_AcceptanceMark_FC_Hrz_RGB.png";
import dinersLogo from "../Icons/asset-decal-diner@2x.png";
import bgImageMobile from "./images/left-image_mobile@2x.jpg";
import bgImage from "./images/left-image@2x.jpg";
import t__ from "../../util/lang/util";
import intro from "../../util/lang/intro";
import isDefaultBrand from "../../util/isDefaultBrand";

const PREFIX = 'AcceptedAt';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  leftContainer: `${PREFIX}-leftContainer`,
  rightContainer: `${PREFIX}-rightContainer`,
  maxWidth308: `${PREFIX}-maxWidth308`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.gridContainer}`]: {
    height: "auto",
    [theme.breakpoints.up("md")]: {
      height: "calc(100vw / 3)",
      minHeight: "380px",
      maxHeight: "550px",
    },
  },

  [`& .${classes.leftContainer}`]: {
    backgroundImage: `url(${bgImageMobile})`,
    width: "100%",
    height: "340px",
    backgroundSize: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: "center",
    },
  },

  [`& .${classes.rightContainer}`]: {
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },

  [`& .${classes.maxWidth308}`]: {
    maxWidth: "308px",
  }
}));

const AcceptedAt = () => {
  const theme = useTheme();

  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <StyledGrid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12} md={6} className={classes.leftContainer}></Grid>
      <Grid item xs={12} md={6} className={classes.rightContainer}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          py={{ xs: 10 }}
          px={{ xs: 5 }}
          textAlign={{ xs: "center", md: "left" }}
          bgcolor="#F2F2F2"
          height="100%"
        >
          <Box
            mb={4}
            data-aos="fade-left"
            data-aos-delay={400}
            data-aos-duration="500"
            data-aos-once="true"
          >
            <Typography
              variant="subtitle2"
              component="h2"
              className={classes.maxWidth308}
            >
              <b>{t__(theme.lang, intro, "acceptedHeader")}</b>
            </Typography>
          </Box>
          <Box
            data-aos="fade-left"
            data-aos-delay={400}
            data-aos-duration="500"
            data-aos-once="true"
          >
            <Typography className={classes.maxWidth308}>
              {t__(theme.lang, intro, "acceptedSub")}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent={isMediumScreen ? "left" : "center"}
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-duration="500"
            data-aos-once="true"
            width="308px"
            marginTop={9}
          >
            {isDefaultBrand(theme) || theme.brand === "cabal" ? (
              <>
                <Box mr={8}>
                  <img src={dinersLogo} alt="" width={87} height={64} />
                </Box>
                <Box>
                  <img src={dgnLogo} alt="" width={103} height={64} />
                </Box>
              </>
            ) : (
              <>
                <Box mr={8} display="flex" justifyContent="center">
                  <img src={theme.palette.brand.icon} alt="" height={62} />
                </Box>
                <Box>
                  <img src={dgnLogo} alt="" width={103} height={64} />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  );
};

export default AcceptedAt;
