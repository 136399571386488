import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import IntroMarquee from "./IntroMarquee";
import TravelGuides from "./TravelGuides";
import AcceptedAt from "./AcceptedAt";
import FeaturedMerchants from "./FeaturedMerchants";
import Footer from "../Footer";
import { Box, Link, useTheme } from "@mui/material/";
import Container from "../Layout/Container";
import AcceptanceMarks from "../AcceptanceMarks";
import isDefaultBrand from "../../util/isDefaultBrand";
import { Helmet } from "react-helmet";
import common from "../../util/lang/common";
import t__ from "../../util/lang/util";
import ErrorBoundary from "../ErrorBoundary";

const PREFIX = "Intro";

const classes = {
  logos: `${PREFIX}-logos`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.logos}`]: {
    display: "inline-block",
    "& img": {
      transition: "all .2s",
    },
    "&:focus, &:hover": {
      "& img": {
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.3)",
      },
    },
  },
}));

const Intro = ({ cities, countryData, ip }) => {
  const selectedCities = ["Paris", "Barcelona", "New York", "Rome", "Istanbul"];

  const theme = useTheme();

  return (
    <Root>
      <Helmet>
        <title>{t__(theme.lang, common, "siteTitle")}</title>
        <meta
          name="description"
          content="Browse popular merchants in top travel destinations, see where to use your card around the world and experience everywhere your card can take you."
        />
        <meta name="keywords" content="Discover card traveling abroad" />
        <link
          rel="canonical"
          href="https://travel.discoverglobalnetwork.com/"
        />
      </Helmet>
      <Box style={{ overflowX: "hidden" }}>
        <ErrorBoundary>
          <Box
            bgcolor="common.white"
            height={62}
            display="flex"
            alignItems="center"
          >
            <Container>
              <Link
                href={isDefaultBrand(theme) ? "/" : `/?brand=${theme.brand}`}
                className={classes.logos}
              >
                <AcceptanceMarks
                  marks={
                    isDefaultBrand(theme) ? ["DCI", "DN"] : theme.headerMarks
                  }
                  variant="xs"
                />
              </Link>
            </Container>
          </Box>

          <IntroMarquee countryData={countryData} cities={cities} />
          <TravelGuides
            countryData={countryData}
            cities={cities}
            selectedCities={selectedCities}
            ip={ip}
          />
          <FeaturedMerchants />
          <AcceptedAt />
          <Footer />
        </ErrorBoundary>
      </Box>
    </Root>
  );
};

Intro.propTypes = {
  cities: PropTypes.array,
  countryData: PropTypes.array,
};

export default Intro;
