import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material/";

import t__ from "../../util/lang/util";
import countryDrawer from "../../util/lang/countryDrawer";

const PREFIX = "AtmBox";

const classes = {
  orangeList: `${PREFIX}-orangeList`,
  longList: `${PREFIX}-longList`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.orangeList}`]: {
    "& span": {
      backgroundColor: theme.palette.primary.light,
      height: "5px",
      width: "5px",
      borderRadius: "5px",
      position: "absolute",
      left: "0px",
      top: 12,
      fontSize: "10px",
    },
  },

  [`& .${classes.longList}`]: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "start",
    "& li": {
      width: "50%",
    },
  },
}));

const AtmBox = ({ countryData }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const hasAtmBanks = countryData.atm_banks[0] !== "";
  const noCCCoverage = countryData.credit_level === "NO COVERAGE";

  return (
    <StyledBox
      bgcolor="background.light"
      borderRadius={theme.spacing(3)}
      padding={mdScreen ? theme.spacing(10) : theme.spacing(5)}
      width="100%"
    >
      <Box mb={3}>
        <Typography variant={mdScreen ? "h3" : "body1"}>
          <b>
            {t__(
              theme.lang,
              countryDrawer,
              `atm.${
                hasAtmBanks
                  ? noCCCoverage
                    ? "coverageAlt"
                    : "coverage"
                  : "noCoverage"
              }.headline`
            )}
          </b>
        </Typography>
      </Box>
      <Typography>
        {t__(
          theme.lang,
          countryDrawer,
          `atm.${hasAtmBanks ? "coverage" : "noCoverage"}.subtitle`
        )}
      </Typography>
      {hasAtmBanks && (
        <>
          <List
            style={{
              listStyle: "bullet",
              marginBottom: theme.spacing(5),
            }}
            className={
              countryData.atm_banks.length > 5 && fullScreen
                ? classes.longList
                : ""
            }
          >
            {countryData.atm_banks.map((a, ind) => {
              if (
                a !==
                "All banks displaying the Discover Network, Diners Club International and PULSE logos"
              ) {
                return (
                  <ListItem
                    dense={true}
                    key={`list${ind}`}
                    className={classes.orangeList}
                  >
                    <span>&nbsp;</span>
                    <b>{a}</b>
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Typography>
            {t__(theme.lang, countryDrawer, "atm.coverage.supplmentary")}
          </Typography>
          <Box mt={5}>
            <Typography>
              {t__(theme.lang, countryDrawer, "atm.coverage.fees")}
            </Typography>
          </Box>
        </>
      )}
    </StyledBox>
  );
};

AtmBox.propTypes = {
  countryData: PropTypes.object.isRequired,
};

export default AtmBox;
