import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MapContext } from "../../contexts";
import { Box, Grid, Typography, useTheme } from "@mui/material/";
import TravelGuidesLogo from "../CountryView/TravelGuidesLogo";
import Container from "../Layout/Container";
import CATEGORIES from "../../util/categories";
import AtmBox from "../AtmBox";
import AcceptanceMarks from "../AcceptanceMarks";
import CountryCallout from "./CountryCallout";
import t__ from "../../util/lang/util";
import cityViewCopy from "../../util/lang/cityViewCopy";
import common from "../../util/lang/common";
import TopAttractionsCarousel from "./TopAttractionsCarousel";
import MerchantsTable from "./MerchantsTable";
import MerchantDrawer from "./MerchantDrawer";
import MerchantSpotlight from "./MerchantSpotlight";

const CityView = ({
  activePin,
  cityView,
  filteredMerchants,
  map,
  setActivePin,
  setFilteredMerchants,
  setMerchantTooltip,
  visibleMerchants,
  ...props
}) => {
  const { acceptanceData, cities, filter, merchants, drawer, setDrawer } =
    useContext(MapContext);
  const [countryData, setCountryData] = useState(null);
  const theme = useTheme();
  const isUSA = countryData?.cca3 === "USA";

  useEffect(() => {
    if (!acceptanceData) return;
    const data = acceptanceData.find(
      (a) => a.cca3 === cities.find((c) => c.city === cityView).country
    );
    setCountryData(data);
  }, [acceptanceData, cities, cityView]);

  if (!countryData) return "";

  if (filter) {
    return (
      <Container pt={10} pb={visibleMerchants.length === 0 ? 10 : 0}>
        {visibleMerchants.length > 0 && filteredMerchants && (
          <Typography>
            <>
              {filter === "all" ? (
                t__(theme.lang, common, "showingAllRecommendedPlaces")
              ) : (
                <>
                  {["restaurants", "retail", "hotels"].includes(filter) ? (
                    <>
                      {t__(theme.lang, common, "showingRecommendedPlacesTo")}{" "}
                      {t__(
                        theme.lang,
                        common,

                        `categories.${CATEGORIES.find(
                          (c) => c.mcc === filter
                        ).title.toLowerCase()}`
                      )}{" "}
                      {t__(theme.lang, common, "here")}
                    </>
                  ) : (
                    <>
                      {t__(theme.lang, common, "showingRecommended")}{" "}
                      {t__(
                        theme.lang,
                        common,

                        `categories.${CATEGORIES.find(
                          (c) => c.mcc === filter
                        ).title.toLowerCase()}`
                      )}{" "}
                      {t__(theme.lang, common, "here")}
                    </>
                  )}
                </>
              )}
            </>
          </Typography>
        )}
        {visibleMerchants && (
          <MerchantsTable
            visibleMerchants={visibleMerchants}
            map={map}
            drawer={drawer}
            setDrawer={setDrawer}
          />
        )}
        {drawer && (
          <MerchantDrawer
            activePin={activePin}
            drawer={drawer}
            map={map}
            setDrawer={setDrawer}
            setActivePin={setActivePin}
            marks={countryData.credit_mark}
          />
        )}
      </Container>
    );
  } else {
    return (
      <>
        <Container pt={{ xs: 4, md: 6 }}>
          <TravelGuidesLogo />
          <Box marginTop={3} marginBottom={3}>
            <Typography variant="h2">
              <b>
                {t__(
                  theme.lang,
                  cityViewCopy,
                  `${cityView
                    .replace(" ", "")
                    .toLowerCase()}.travelGuideHeadline`
                )}
              </b>
            </Typography>
          </Box>
          <Typography>
            {t__(
              theme.lang,
              cityViewCopy,
              `${cityView.replace(" ", "").toLowerCase()}.travelGuideBody`
            )}
          </Typography>
          <Box
            borderRadius={theme.spacing(3)}
            border={1}
            padding={5}
            marginTop={5}
            marginBottom={10}
            sx={{
              borderColor: `${theme.palette.borders.light} !important`,
            }}
          >
            <Typography>
              {t__(theme.lang, cityViewCopy, "acceptedPaymentsIn")} {cityView}
            </Typography>
            <AcceptanceMarks
              marks={countryData.credit_mark}
              variant="small"
              style={{ marginTop: theme.spacing(3) }}
            />
          </Box>
        </Container>
        {/*until we know what we are doing here keeping this component a one-off*/}
        {cityView === "Barcelona" && <MerchantSpotlight />}
        <Container>
          <Box marginTop={7}>
            <Typography variant="h2">
              <b>{t__(theme.lang, common, "mustSeeAttractions")}</b>
              <br />
            </Typography>
          </Box>
          {merchants && (
            <Box marginRight={theme.spacing(-2.5)} marginTop={7}>
              <TopAttractionsCarousel
                attractions={[...merchants].filter(
                  (m) => m.mcc === "top_attractions"
                )}
                map={map}
              />
            </Box>
          )}

          <Box mt={10} mb={10}>
            <Grid container spacing={6}>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <CountryCallout
                  countryData={countryData}
                  setFilteredMerchants={setFilteredMerchants}
                />
              </Grid>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <AtmBox countryData={countryData} />
              </Grid>
            </Grid>
          </Box>
          {isUSA && (
            <Box mb={10}>
              <Typography variant="body2" color="textSecondary">
                *{t__(theme.lang, cityViewCopy, "nilson")}
              </Typography>
            </Box>
          )}
        </Container>

        {drawer && (
          <MerchantDrawer
            activePin={activePin}
            drawer={drawer}
            map={map}
            setDrawer={setDrawer}
            setActivePin={setActivePin}
            marks={countryData.credit_mark}
          />
        )}
      </>
    );
  }
};

CityView.propTypes = {
  cityView: PropTypes.string,
  filteredMerchants: PropTypes.array,
  map: PropTypes.object.isRequired,
  props: PropTypes.object,
  setMerchantTooltip: PropTypes.func,
  visibleMerchants: PropTypes.array,
};

export default CityView;
